<div [ngStyle]="{'background-color': scope.baseColor ? scope.baseColor : '#fff'}">
    <div class="container vh-100 d-flex align-items-center">
        <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 center width-mobile-auto">
            <form [formGroup]="this.form">
                <img src="{{scope.brandImageUrl}}" alt="logo" class="center card-header-logo">
                <h2 class="fs-xxl fw-500 mt-4 text-white" style="text-align: center;">
                    Bem-vindo(a)</h2>
                <span class="text">{{scope.welcomeText}}</span>
                <br />
                <span *ngIf="!!scope.showDocumentsPortalUrl && !scope.isRepresentative" class="text">Caso queira acessar todas as suas assembleias,
                    favor clicar neste link (<a class="btn-link text-white" style="text-decoration: none;" target="_blank" href="{{scope.documentsPortalUrl}}">Portal do Cotista</a>).</span>
                <br />
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 center width-mobile-auto">
                    <div class="card p-2 rounded-plus bg-faded height-100" style="padding: 1.5rem !important;">
                        <p style="text-align: center;">Digite os cinco primeiros dígitos do seu CPF ou CNPJ <span
                                style="color: red;">*</span></p>
                        <div class="form-group">
                            <input formControlName="cpf" id="cpfNum" class="form-control" (keyup)="enterCpf($event)"
                                required autofocus type="text" maxlength="5" pattern="([0-9]{5})" autocomplete="off"
                                title="Digite apenas os 5 primeiros números do seu documento CPF" />
                            <div id="invalid-feedback" class="text-danger"></div>
                        </div>
                        <div class="form-group" style="text-align: center;">
                            <button [disabled]="!form.valid" *ngIf="!isLoading" id="btnGo" style="width:40%;"
                                class="btn btn-secondary button" (click)="onSubmit()"
                                [ngStyle]="{'btn-secondary:hover': scope.baseColor ? scope.baseColor : '#0c85e2'}"><span>Entrar
                                </span></button>
                            <div *ngIf="isLoading">
                                <div class="spinner-border" role="status" style="font-size: 1rem!important;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
