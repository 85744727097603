declare var $: any;
declare var initApp: any;
import * as FW from "../framework/core";

declare function displayToast(title: string, message: string, type: string): any;

export class UIBehavior {
    static notify(title: string, message: string, type: "info" | "success" | "warning" | "error"): void {

        if (!FW.isNullOrBlank(title)) {
            title = `<strong>${title}</strong>`
        } else {
            title = null;
        }

        displayToast(title, message, type);
    }
}