import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class NavigationService {
    private createdFormControlNameVoteSubject = new Subject<any>();
    private createdFormControlNameCommentVoteSubject = new Subject<any>();
    private attachmentedSubject = new Subject<any>();
    private isAuthenticate = false;

    constructor() {
    }

    set authenticate(value: boolean) {
        this.isAuthenticate = value;
    }

    get authenticate(): boolean {
        return this.isAuthenticate;
    }

    createFormControlNameComment(id: string): void {
        this.createdFormControlNameCommentVoteSubject.next(id);
    }

    createdFormControlNameComment(): Observable<any> {
        return this.createdFormControlNameCommentVoteSubject.asObservable();
    }

    createFormControlNameVote(id: string): void {
        this.createdFormControlNameVoteSubject.next(id);
    }

    createdFormControlNameVote(): Observable<any> {
        return this.createdFormControlNameVoteSubject.asObservable();
    }

    newAttachment(files: any[]): void {
        this.attachmentedSubject.next(files);
    }

    attachmented(): Observable<any> {
        return this.attachmentedSubject.asObservable();
    }
}
