export class CompanyRepresentativeAuthentication{
    token: string;
    meeting: string;

    constructor(
        token: string,
        meeting: string
    ) {
        this.token = token;
        this.meeting = meeting;
    }
}
