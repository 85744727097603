<form [formGroup]="this.form">
    <div class="row">
        <div class="col" *ngFor="let option of deliberation.options">
            <div class="form-check form-check-inline">
                <input class="form-check-input icon-pointer" type="radio" id="{{identityVoteOption(option.id)}}"
                    value="{{option.id}}" [formControlName]="formControlNameVote"
                    [attr.disabled]="disableVote ? true : null" required>
                <label class="form-check-label icon-pointer" for="{{identityVoteOption(option.id)}}">{{option.text}}</label>
            </div>
        </div>
    </div>
</form>