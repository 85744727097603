import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrEmpty } from 'src/app/framework/core';
import { NavigationService } from '../services/navigation.service';

declare var $: any;
@Component({
    selector: 'conta-ordem-auth',
    templateUrl: './conta-ordem-auth.component.html',
    styleUrls: ['./conta-ordem-auth.component.scss']
})
export class ContaOrdemAuthComponent {
  public cpfNumber = '';
  public form: FormGroup;
  public isLoading = false;
  private attemptLogin = false;
  public scope: any = null;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private navigationService: NavigationService) {
    this.form = this.fb.group({
      cpf: ['', Validators.required]
    });
  }

  public enterCpf(event: KeyboardEvent): any {
    let val = $('#cpfNum').val();
    if (!/^[0-9]*$/.test(val)) { $('#cpfNum').val(val.substring(0, val.length - 1)); }
    this.cpfNumber = $('#cpfNum').val();

    if (!this.form.valid) { $('#invalid-feedback').html(""); }

    if (event.keyCode === 13 && this.form.valid) { this.onSubmit(); }
  }

  async ngOnInit(): Promise<void> {
    $('#divLoading').hide();
    localStorage.removeItem('short');
    let content = history.state;
    if(content.scope != null)
      this.setScope(content);
    else
      this.getScope();
  }

  async onSubmit(): Promise<void> {
    this.isLoading = true;
    let isValid = true;
    this.attemptLogin = true;
    if (this.validate()) {
      if (!isNullOrEmpty(localStorage.getItem('documentNumber'))) {
        const documentNumber = window.atob(localStorage.getItem('documentNumber'));
        isValid = documentNumber.substring(0, 5) === this.cpfNumber;
        localStorage.setItem('documentNumberLogin', documentNumber);
      } else if (!isNullOrEmpty(localStorage.getItem('documentNumberRepresentative1'))) {
        const documentNumberRepresentative1 = window.atob(localStorage.getItem('documentNumberRepresentative1')) || '';
        isValid = documentNumberRepresentative1.substring(0, 5) === this.cpfNumber;
        localStorage.setItem('documentNumberLogin', documentNumberRepresentative1);
      }
      if (!isNullOrEmpty(localStorage.getItem('documentNumberRepresentative2')) && !isValid) {
        const documentNumberRepresentative2 = window.atob(localStorage.getItem('documentNumberRepresentative2')) || '';
        isValid = documentNumberRepresentative2.substring(0, 5) === this.cpfNumber;
        localStorage.setItem('documentNumberLogin', documentNumberRepresentative2);
      }

      if (isValid) {
        if(localStorage.getItem('token') == null || localStorage.getItem('meeting') == null){
          $('#invalid-feedback').html("Acesse como um link valido para prosseguir!");
          this.isLoading = false;
        }
        else {
          this.navigationService.authenticate = true;
          localStorage.setItem('short', window.btoa('OK'));
          this.isLoading = false;
          this.error('');

          this.router.navigate(['conta-ordem', localStorage.getItem('token'), localStorage.getItem('meeting')]);
        }
      } else {
        $('#invalid-feedback').html("O documento digitado não está correto.");
        this.isLoading = false;
      }
    } else {
      this.navigationService.authenticate = false;
    }
  }

  public validate(): boolean {
    const regex = new RegExp('([0-9]{5})');
    this.cpfNumber = this.form.get('cpf').value;
    return regex.test(this.cpfNumber);
  }

  private error(error: string): void {
    $('#error').text(error);
  }

  get cpfInvalid(): boolean {
    return this.form.valid && this.attemptLogin && !this.navigationService.authenticate;
  }

  private setScope(content: any) {
    this.scope = {
      baseColor: content.scope.baseColor,
      title: content.scope.title,
      brandImageUrl: content.scope.brandImageUrl,
      documentsPortalUrl: content.documentsPortalUrl,
      isRepresentative: content.isRepresentative,
      showDocumentsPortalUrl: content.scope.showDocumentsPortalUrl,
      welcomeText: content.scope.welcomeText
    }
    localStorage.setItem('scope', JSON.stringify(this.scope));
  }

  private getScope() {
    if(!isNullOrEmpty(localStorage.getItem('scope'))) {
      this.scope = JSON.parse(localStorage.getItem('scope'));
    }
  }
}
