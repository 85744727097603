<form [formGroup]="form">
    <div formArrayName="representatives"
        *ngFor="let item of form.get('representatives')['controls']; let i = index;let last = last;">
        <div class="card space" *ngIf="canList(i)">
            <div class="card-header" *ngIf="canList(i)">
                <div class="row">
                    <div class="col-10"><span>{{textRepresentative(i)}}</span>
                    </div>
                    <div class="col-2 float-right">
                        <div class="icon-pointer" (click)="add(i)">
                            <i class="bi bi-plus-circle float-right" [hidden]="!canAdd(last)"
                                [ngStyle]="{'color': verifyInvalid(i) ? 'lightgray' : ' rgb(64, 64, 184)'}"
                                style="margin-left: 5px;font-size: 1.3rem;"></i>
                        </div>
                        <div class="icon-pointer" (click)="remove(i)"><i class="bi bi-trash float-right"
                                [hidden]="!canRemove(i)"
                                [ngStyle]="{'color': !this.currentMeeting ? 'lightgray' : ' rgb(219, 96, 96)'}"
                                style="margin-left: 5px; font-size: 1.3rem; color: rgb(219, 96, 96);"></i></div>
                        <div class="icon-pointer" (click)="indicate(i)"><i class="bi bi-star-fill float-right"
                                style="font-size: 1.3rem;"
                                [ngStyle]="{'color': isSelected(i) ? 'rgb(221, 221, 67)' : 'lightgray'}"></i></div>
                    </div>
                </div>
                <div class="row" style="margin-top: -5px; margin-bottom: 0px;" [hidden]="!isSelected(i)">
                    <div class="col">
                        <span class="text-info text-start" style="font-size: 0.7rem;">Será
                            enviado
                            para este representante um e-mail com o link da votação.</span>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="canList(i)">
                <div class="col-md-auto" [formGroupName]="i">
                    <div class="row">
                        <div class="col-sm-4 col-md-4">
                            <label for="name" class="form-label">Nome<span class="text-danger">
                                    *</span></label>
                            <input type="text" class="form-control" id="name" placeholder="Informe o nome"
                                [attr.disabled]="!canEdit(i) ? true : null" formControlName="name" aria-label="Name"
                                [class.is-invalid]="checkInvalidity(i, 'name')">
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <label for="cpf" class="form-label">CPF<span class="text-danger"> *</span></label>
                            <input type="text" mask="000.000.000-00" class="form-control" id="cpf"
                                placeholder="Informe o cpf" aria-label="Cpf" formControlName="documentNumber"
                                [attr.disabled]="!canEdit(i) ? true : null"
                                [class.is-invalid]="checkInvalidity(i, 'documentNumber')">
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <label for="celular" class="form-label">Celular<span class="text-danger"> *</span></label>
                            <input type="text" mask="(00) 0 0000-0000||(00) 0000-0000" class="form-control" id="celular"
                                placeholder="Informe o celular" aria-label="Phone" formControlName="phone"
                                [class.is-invalid]="checkInvalidity(i, 'phone')">
                        </div>
                    </div>
                    <div class="row space">
                        <div class="col-sm-4 col-md-4">
                            <label for="email" class="form-label">E-mail<span class="text-danger"> *</span></label>
                            <input type="text" class="form-control" id="email" placeholder="Informe o e-mail"
                                aria-label="Email" formControlName="email"
                                [class.is-invalid]="checkInvalidity(i, 'email')">
                        </div>
                        <div class="col-sm-4 col-md-4">
                            <label for="effective-date" class="form-label">Data fim da Vigência da Procuração<span
                                    class="text-danger">
                                    *</span></label>
                            <input type="date" class="form-control" id="effective-date"
                                placeholder="Informa e data de vigência" aria-label="EffectiveDate"
                                formControlName="procurationEndDate"
                                [attr.disabled]="!canEditProcurationEndDate(i) ? true : null"
                                [class.is-invalid]="checkInvalidity(i, 'procurationEndDate')" dateValidatorDirective>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <input #formFile class="form-control" type="file" name="formFile" id="formFile" accept=".pdf"
                                (change)="onFileSelected($event)" hidden="true">
                            <button type="button" class="btn btn-secondary btn-block"
                                [ngClass]="{'border border-danger': checkAttachmentInvalidity(i)}"
                                (click)="this.attach(i)" style="margin-top: 32px;"
                                [disabled]="isUploading || (verifySelection(i) > 0 && !this.acceptsMultipleFiles())">
                                <span>Anexar
                                    <span class="badge bg-danger"
                                        [hidden]="isUploading && last">{{verifySelection(i)}}</span>
                                </span>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    [hidden]="!isUploading || (isUploading && !last)"></span>
                            </button>
                        </div>
                        <div class="col-sm-1 col-md-1">
                            <button type="button" class="btn btn-link float-start" (click)="viewAttachment(i)"
                                data-bs-toggle="modal" [hidden]="canEditProcurationEndDate(i) && !verifySelection(i)"
                                data-bs-target="#staticBackdrop" style="margin-top: 32px;"><i
                                    class="bi bi-eye icon-pointer"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Anexos</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row" [hidden]="!this.acceptsMultipleFiles()">
                    <div class="col">
                        <span class="text-info text-start" style="font-size: 0.7rem;">Em caso de múltiplos arquivos, ao salvar o representante, serão combinados em um único arquivo PDF.</span>
                    </div>
                </div>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let file of this.uploadedFiles">
                            <th style="font-weight:normal">{{file.name}}</th>
                            <td class="text-center">
                                <i class="bi bi-trash icon-pointer" (click)="removeFile(file.id)"
                                    style="font-size: 1.2rem; color: red;"></i>
                                <i class="bi bi-eye icon-pointer" style="margin-left: 10px;" (click)="viewFile(file)"
                                    style="font-size: 1.2rem; color: green;"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
