import { CompanyRepresentativeComponent } from './pages/company-representative/company-representative.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VoteComponent } from './pages/vote/vote.component';
import { VoteIndexDocComponent } from './pages/vote/vote-index-doc/vote-index-doc.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { VoteItemComponent } from './pages/vote/vote-items/vote-item/vote-item.component';
import { VoteItemOptionComponent } from './pages/vote/vote-items/vote-item/vote-item-option/vote-item-option.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AcceptanceTermComponent } from './pages/vote/acceptance-term/acceptance-term.component';
import { VoteItemCommentComponent } from './pages/vote/vote-items/vote-item/vote-item-comment/vote-item-comment.component';
import { serviceProviders } from './services/serviceprovider';
import { CompanyRepresentativeItemComponent } from './pages/company-representative/company-representative-item/company-representative-item.component';
import { NgxMaskModule } from 'ngx-mask';
import { MASK_CONFIG } from './app.mask';
import { ContaOrdemComponent } from './pages/conta-ordem/conta-ordem.component';
import { ContaOrdemAuthComponent } from './pages/vote/conta-ordem-auth/conta-ordem-auth.component';

@NgModule({
  declarations: [
    AppComponent,
    VoteComponent,
    NotFoundComponent,
    VoteItemComponent,
    VoteItemOptionComponent,
    VoteIndexDocComponent,
    AcceptanceTermComponent,
    CompanyRepresentativeComponent,
    CompanyRepresentativeItemComponent,
    VoteItemCommentComponent,
    ContaOrdemComponent,
    ContaOrdemAuthComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxMaskModule.forRoot(MASK_CONFIG)
  ],
  providers: [serviceProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
