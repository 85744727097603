import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'src/app/app.error-handler';
import { environment } from 'src/environments/environment';
import * as FW from '../../../framework/core';
import { CompanyRepresentativeAuthentication } from '../models/Company-representative-authentication.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()
export class CompanyRepresentativeService {

    constructor(private http: HttpClient) { }

    public authenticate(model: CompanyRepresentativeAuthentication): Observable<any> {
        return this.http.post<any>(`${environment.baseApiUrl}/company-representative/authentication`, new FW.Envelope(model), httpOptions)
            .pipe(catchError(ErrorHandler.handleError));
    }

    public save(model: any): Observable<any> {
        return this.http.post(`${environment.baseApiUrl}/company-representative/save`, new FW.Envelope(model))
            .pipe(catchError(ErrorHandler.handleError));
    }

    public exclude(model: any): Observable<any> {
        return this.http.post(`${environment.baseApiUrl}/company-representative/exclude`, new FW.Envelope(model))
            .pipe(catchError(ErrorHandler.handleError));
    }
}
