import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'src/app/app.error-handler';
import { environment } from 'src/environments/environment';
import * as FW from '../../../framework/core';
import { AcceptanceTermAnswer } from '../models/acceptance-term-answer.model';
import { VoteAuthentication } from '../models/vote-authentication.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()
export class AcceptanceTermService {

    constructor(private http: HttpClient) { }

    public hasAcceptedTerm(model: VoteAuthentication): Observable<any> {
        return this.http.post<any>(`${environment.baseApiUrl}/meetinguseracceptanceterm/hasaccepted`, new FW.Envelope(model), httpOptions)
            .pipe(catchError(ErrorHandler.handleError));
    }

    public loadTermText(model: VoteAuthentication): Observable<any> {
        return this.http.post<any>(`${environment.baseApiUrl}/meetinguseracceptanceterm/getacceptancetermhtml`, new FW.Envelope(model), httpOptions)
            .pipe(catchError(ErrorHandler.handleError));
    }

    public loadSecondTermText(model: VoteAuthentication): Observable<any> {
        return this.http.post<any>(`${environment.baseApiUrl}/meetinguseracceptanceterm/getsecondacceptancetermhtml`, new FW.Envelope(model), httpOptions)
            .pipe(catchError(ErrorHandler.handleError));
    }

    public saveTermAnswer(model: AcceptanceTermAnswer): Observable<any> {
        return this.http.post<any>(`${environment.baseApiUrl}/meetinguseracceptanceterm/save`, new FW.Envelope(model), httpOptions)
            .pipe(catchError(ErrorHandler.handleError));
    }
}