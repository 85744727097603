import { Injectable } from '@angular/core';
import { IMessage } from 'src/app/models/IMessage';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MessageSwalService {

  constructor() { }

  displayMessage(msg: IMessage, callback?: any): void {
    switch (msg.warninglevel) {
      case 0:
        if (callback) {
          Swal.fire('Sucesso', msg.message, 'success').then(callback);
        } else {
          Swal.fire('Sucesso', msg.message, 'success');
        }
        break;
      case 1:
        if (callback) {
          Swal.fire('Alerta', msg.message, 'warning').then(callback);
        } else {
          Swal.fire('Alerta', msg.message, 'warning');
        }
        break;
      case 2:
        Swal.fire('Erro', msg.message, 'error');
        break;
      default:
        Swal.fire('Erro', msg.message, 'error');
        break;
    }
  }
}
