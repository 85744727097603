import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandler } from '../../../app.error-handler';
import { environment } from '../../../../environments/environment';
import * as FW from '../../../framework/core';
import { WarningLevel } from '../../../framework/enums';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class MeetingParticipantService {
  constructor(private http: HttpClient) { }

  public listByDocument(model: any): Observable<any> {
    return this.http.post(`${environment.baseApiUrl}/meetingParticipant/ListByDocument`, new FW.Envelope(model), httpOptions)
      .pipe(catchError(ErrorHandler.handleError));
  }

  distributorDeliberations(token: string, meeting: string, participant: string): Observable<FW.Envelope<string>> {
    const headers = new HttpHeaders()
      .set('Authorization', `BEARER ${token}`);

    const params = new HttpParams()
      .set('meeting', meeting)
      .set('participant', participant);

    return this.http
      .get<FW.Envelope<string>>(`${environment.baseApiUrl}/meetingParticipant/distributor-deliberations`, { headers, params })
      .pipe(catchError(ErrorHandler.handleError));
  }

  validateVoteFile(model: {
    token: string
    meeting: string,
    participant: string,
    azurePath: string
  }): Observable<FW.Envelope<string>> {
    return this.http
      .post<FW.Envelope<string>>(`${environment.baseApiUrl}/meetingParticipant/validate-vote-file`, new FW.Envelope(model), httpOptions)
      .pipe(catchError(ErrorHandler.handleError));
  }

  validateProcurationFile(model: {
    token: string
    meeting: string,
    participant: string,
    azurePath: string
  }): Observable<FW.Envelope<string>> {
    return this.http
      .post<Observable<FW.Envelope<string>>>(
        `${environment.baseApiUrl}/meetingParticipant/validate-procuration-file`,
        new FW.Envelope(model), httpOptions
      )
      .pipe(catchError(ErrorHandler.handleError));
  }

  saveDistributorVotes(model: {
    token: string
    meeting: string,
    participant: string,
    voteFile: string,
    procurationFile: string
  }): Observable<FW.Envelope<any>> {
    return this.http
      .post<FW.Envelope<any>>(`${environment.baseApiUrl}/meetingParticipant/save-distributor-votes`, new FW.Envelope(model), httpOptions)
      .pipe(catchError(ErrorHandler.handleError));
  }
}
