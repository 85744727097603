import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileService } from 'src/app/services/application/file.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { Deliberation, Votation } from '../../models/votation.model';
import { NavigationService } from '../../services/navigation.service';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;
declare var window: any;

@Component({
  selector: 'app-vote-item',
  templateUrl: './vote-item.component.html',
  styleUrls: ['./vote-item.component.scss']
})
export class VoteItemComponent implements OnInit, OnChanges {

  @Input() deliberation: Deliberation;
  @Input() votationOptions: Votation;
  @Input() uploadLimited: boolean;
  @Input() hiddeAttach: boolean;
  @Input() form: FormGroup;

  uploadedFiles = [];

  private uploading: boolean;

  constructor(
    private fileService: FileService,
    private messageService: MessageSwalService,
    private navigationService: NavigationService,
    public sanitizer: DomSanitizer) { }

  get isUploading(): boolean {
    return this.uploading;
  }

  get maxNumberUpload(): boolean {
    return this.uploadLimited && this.uploadedFiles.length === 1;
  }

  get canVote(): boolean {
    if (this.votationOptions) {
      if (this.votationOptions.currentMeeting && this.votationOptions.participant.canVote) {
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    this.uploading = false;
  }

  ngOnChanges(): void {
  }

  public attach(): void {
    $('#formFile').click();
  }

  public onFileSelected(event: any): void {
    this.uploading = true;
    const file = event.target.files[0];

    if(!file.type.includes('pdf')){
      this.messageService.displayMessage({ warninglevel: 1, message: 'Extensão inválida, selecione um arquivo pdf.' }, () => { });
      this.uploading = false;
      return;
    }

    this.fileService.upload(file).then(uploadedFile => {
      if (uploadedFile == null) {
        this.uploading = false;
        this.messageService.displayMessage({ warninglevel: 1, message: 'Formato de arquivo não aceito!' }, () => { });
        return;
      }
      this.uploading = false;
      this.uploadedFiles.push(uploadedFile);
      this.navigationService.newAttachment(this.uploadedFiles);
    }).catch(error => {
      this.uploading = false;
      if (error.message !== 'Cannot read property \'size\' of undefined') {
        this.messageService.displayMessage({ warninglevel: 1, message: error.message }, () => { });
      }
    });
  }

  async removeFile(id: string): Promise<void> {
    await this.fileService.remove(localStorage.getItem('token'), id)
      .then(_ => {
        this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== id);
        this.navigationService.newAttachment(this.uploadedFiles);
      })
      .catch(error => {
        this.uploading = false;
        this.messageService.displayMessage({ warninglevel: 1, message: error.message }, () => { });
      });
  }

  openFile(azurePath: string): void {
    this.fileService.getUrlSafe(localStorage.getItem('token'), azurePath)
      .then(urlSafe => {
        window.openResourceLocalUrl(urlSafe, window.getMimeType(azurePath.toLowerCase()));
      });
  }

}
