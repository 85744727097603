import { Component, DebugElement, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNull, isNullOrEmpty } from 'src/app/framework/core';
import { FileService } from 'src/app/services/application/file.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { Votation, CommentType } from './models/votation.model';
import { VoteAuthentication } from './models/vote-authentication.model';
import { NavigationService } from './services/navigation.service';
import { VoteService } from './services/vote.service';

declare var $: any;

@Component({
  selector: 'app-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss']
})
export class VoteComponent implements OnInit {
  votationOptions: Votation;
  termAccepted: boolean;
  form: FormGroup;

  private votesIdenity: Array<string> = new Array<string>();
  private commentIdentity: Array<string> = new Array<string>();
  private attachments: Array<any> = new Array<any>();
  private limitedDocumentUpload = true;
  private attachmentIsOptional = false;
  private votationComputed = false;
  private downloading = false;
  private saving: boolean;
  private commentType: typeof CommentType = CommentType;
  private procurationFile: any;
  private uploading: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private voteService: VoteService,
    private messageService: MessageSwalService,
    private navigationService: NavigationService,
    private fileService: FileService) {

    if (!this.navigationService.authenticate) {
      localStorage.removeItem('short');
      localStorage.removeItem('documentNumber');
      localStorage.removeItem('documentNumberRepresentative1');
      localStorage.removeItem('documentNumberRepresentative2');
    }

    if (!isNullOrEmpty(this.route.snapshot.params.token) &&
      !isNullOrEmpty(this.route.snapshot.params.meeting)) {
      localStorage.setItem('token', this.route.snapshot.params.token);
      localStorage.setItem('meeting', this.route.snapshot.params.meeting);

      this.navigationService.createdFormControlNameComment()
        .subscribe(id => this.commentIdentity.push(id));

      this.navigationService.createdFormControlNameVote()
        .subscribe(id => this.votesIdenity.push(id));

      this.navigationService.attachmented()
        .subscribe(files => this.attachments = files);
    }
  }

  get isSaving(): boolean {
    return this.saving;
  }

  get isLoading(): boolean {
    return this.votationOptions == null;
  }

  get uploadLimited(): boolean {
    return this.limitedDocumentUpload;
  }

  get invalidState(): boolean {
    if (this.checkVotes() && this.checkAttachments() && this.checkVotesComment()) {
      return false;
    }
    return true;
  }

  get hiddeAttach(): boolean {
    if (!isNull(this.votationOptions)) {
      if (this.votationOptions.participant.personType === 'PF' || this.votationOptions.participant.personType === 'PJ') {
        if (this.votationOptions.participant.particularityCode === 'R1' || this.votationOptions.participant.particularityCode === 'R2') {
          this.limitedDocumentUpload = true;
          this.attachmentIsOptional = this.votationOptions.participant.particularityCode === 'R2';
          return false;
        } else if (this.votationOptions.participant.particularityCode === 'A1') {
          this.limitedDocumentUpload = false;
          return false;
        }
      }
    }
    return true;
  }

  get textVotation(): string {
    return this.votationComputed ? 'Alterar voto' : 'Votar';
  }

  get messageValidation(): boolean {
    return !isNullOrEmpty(this.votationOptions.messageValidation);
  }

  get isDownloading(): boolean {
    return this.downloading;
  }

  async ngOnInit(): Promise<void> {
    this.saving = false;
    this.initForm();
    if (this.verifyParams()) {
      var documentNumberLogin = this.route.snapshot.params.documentNumberLogin;

      if (!documentNumberLogin)
        documentNumberLogin = window.btoa(localStorage.getItem('documentNumberLogin'));

      await this.voteService.authenticate(this.getParams(), documentNumberLogin).toPromise()
        .then(response => {
          this.votationOptions = response.content;
          this.form.get('meeting').setValue(this.votationOptions.meeting);
          this.form.get('participant').setValue(this.votationOptions.participant);
          var shortOk = window.atob(localStorage.getItem('short'));
          if (this.route.snapshot.params.portal != null) { shortOk = 'OK'; }
          if (shortOk !== 'OK') {
            localStorage.removeItem('documentNumber');
            localStorage.removeItem('documentNumberRepresentative1');
            localStorage.removeItem('documentNumberRepresentative2');

            localStorage.setItem('documentNumber', this.votationOptions.participant.documentNumber);
            let index = 1;
            this.votationOptions.participant.representatives.forEach(representative => {
              localStorage.setItem(`documentNumberRepresentative${index}`, representative.documentNumber);
              index += 1;
            });

            this.router.navigate(['/vote-index-doc'], { state: response.content });
          }
        })
        .catch(error => {
          this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
          this.router.navigate([`/not-found`]);
        });
    } else {
      localStorage.clear();
      this.router.navigate([`/not-found`]);
    }
  }

  public download(): void {
    this.downloading = true;
    this.voteService.downloadAdditionalDocuments(localStorage.getItem('token'), localStorage.getItem('meeting')).toPromise()
      .then(response => {
        response.content.forEach(element => {
          const encodedUri = encodeURI(element.azurePath);
          const link = document.createElement('a');
          link.setAttribute('href', 'data:application/octet-stream;charset=utf-8;base64,' + encodedUri);
          link.setAttribute('download', element.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
        this.downloading = false;
      }).catch(_ => this.downloading = false);

  }

  public exit(): void {
    localStorage.clear();
    this.router.navigate([`/vote-index-doc`]);
  }

  public save(): void {
    if (this.form.invalid) {
      return;
    }

    if (this.urlHasCodCo() && !this.procurationFile) {
      this.messageService.displayMessage({ warninglevel: 1, message: 'É necessário anexar a procuração.' }, () => { });
      return;
    }

    this.saving = true;
    const deliberationsVote: any[] = [];
    this.votesIdenity.forEach(vote => {
      const voteOption = this.form.get(vote).value;
      const deliberation = vote.replace('vote', '');
      const comment = this.getCommentTextByDeliberation(deliberation);
      deliberationsVote.push({ voteOption, deliberation, comment });
    });

    const model = {
      token: localStorage.getItem('token'),
      meeting: this.votationOptions.meeting,
      participant: this.votationOptions.participant,
      deliberationsVote,
      attachments: this.attachments,
      documentNumberVote: btoa(localStorage.getItem('documentNumberLogin')),
      codCo: this.route.snapshot.params.codCo,
      procurationFile: this.procurationFile ? this.procurationFile.azurePath : ''
    };

    this.voteService.save(model).toPromise()
      .then(_ => {
        this.saving = false;
        this.votationComputed = true;
        this.messageService.displayMessage({ warninglevel: 0, message: 'Votação realizada com sucesso.' }, () => {
          if (this.urlHasCodCo()) {
            this.router.navigateByUrl(
              `/conta-ordem/` +
              encodeURIComponent(this.route.snapshot.params.token) + '/' +
              encodeURIComponent(this.route.snapshot.params.meeting) + '/' +
              encodeURIComponent(localStorage.getItem('documentNumberLogin'))
            );
          }
        });
      })
      .catch(error => {
        this.saving = false;
        this.votationComputed = false;
        this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
      });
  }

  public getCommentTextByDeliberation(deliberationVote: string): string {
    var commentText: string = null;
    if (this.commentIdentity.length > 0) {
      const deliberationFilter = this.commentIdentity.filter((comment) => {
        return comment === 'comment' + deliberationVote
      });

      if (deliberationFilter.length > 0) {
        deliberationFilter.forEach(comment => {
          const deliberation = comment.replace('comment', '');
          if (deliberation == deliberationVote) {
            commentText = this.form.get(comment).value;
          }
        });
      }
    }

    return commentText;
  }

  private initForm(): void {
    this.form = this.fb.group({
      meeting: ['', Validators.required],
      participant: ['', Validators.required]
    });
  }

  private verifyParams(): boolean {
    return !isNullOrEmpty(localStorage.getItem('token')) &&
      !isNullOrEmpty(localStorage.getItem('meeting'));
  }

  private getParams(): VoteAuthentication {
    if (!this.verifyParams()) {
      return null;
    }

    return new VoteAuthentication(
      localStorage.getItem('token'),
      localStorage.getItem('meeting'),
      this.route.snapshot.params.codCo);
  }

  private checkVotes(): boolean {
    let isValid = false;
    if (this.votationOptions && this.votationOptions.deliberations.length) {
      if (this.votationOptions.deliberations.length === this.votesIdenity.length) {
        isValid = true;
        this.votesIdenity.forEach(id => {
          if (this.form.get(id).invalid) {
            isValid = false;
            return;
          }
        });
      }
    }
    return isValid;
  }

  private checkAttachments(): boolean {
    if (this.attachmentIsOptional) {
      return true;
    } else if (this.hiddeAttach) {
      return true;
    } else if (!this.hiddeAttach && this.attachments.length > 0) {
      return true;
    }
    return false;
  }

  private checkVotesComment(): boolean {
    let isValid = false;
    if (this.votationOptions && this.votationOptions.deliberations.length) {
      const deliberationWithComment = this.votationOptions.deliberations.filter((deliberation) => {
        return deliberation.commentType == CommentType.YES_MANDATORY
      });

      if (deliberationWithComment.length > 0) {
        if (this.commentIdentity.length) {
          isValid = true;
          deliberationWithComment.forEach(deliberation => {
            const comment = this.getCommentTextByDeliberation(deliberation.deliberation);
            if (isNullOrEmpty(comment)) {
              isValid = false;
              return isValid;
            }
          });
        }
      }
      else {
        isValid = true;
        return isValid;
      }
    }
    return isValid;
  }

  public onFileSelected(event: any): void {
    var file = event.target.files[0];

    this.fileService.upload(file).then(uploadedFile => {
      if (uploadedFile == null) {
        this.uploading = false;
        this.messageService.displayMessage({ warninglevel: 1, message: 'Formato de arquivo não aceito!' }, () => { });
        return;
      }
      this.uploading = false;
      this.procurationFile = uploadedFile;
    }).catch(error => {
      this.uploading = false;
      if (error.message !== 'Cannot read property \'size\' of undefined') {
        this.messageService.displayMessage({ warninglevel: 1, message: error.message }, () => { });
      }
    });
  }

  public urlHasCodCo() {
    return this.route.snapshot.params.codCo;
  }

}


