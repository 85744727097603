<div class="row h-100 d-flex justify-content-center align-items-center">
  <div class="col-10" *ngIf="!isLoading">
    <div style="text-align: left;">
      <i (click)="exit()" class="bi bi-box-arrow-left icon-out" style="font-size: 2rem;"></i>
    </div>
    <div class="card space">
      <div class="card-header" [style.background-color]="votationOptions?.scope.baseColor">
        <img [src]="votationOptions?.scope.brandImageUrl" class="card-header-logo logo" alt="">
      </div>
      <h5 class="card-header">
        {{votationOptions?.fund}}
      </h5>
      <div class="row space" *ngIf="messageValidation">
        <div class="col text-center">
          <span class="text-warning">{{votationOptions?.messageValidation}}</span>
        </div>
      </div>
      <div class="row space actions">
        <div class="text-center" style="align-self: flex-end;"
          [ngClass]="{'col-md-6' : !!votationOptions?.canUploadVotesFile, 'col-md-12' : !votationOptions?.canUploadVotesFile}">
          <h6>{{votationOptions?.meetingName}}</h6>
        </div>
        <div class="col-md-3 text-center" *ngIf="!!votationOptions?.canUploadVotesFile">
          <button (click)="download()" class="btn btn-success btn-block"
            [disabled]="!votationOptions.currentMeeting || votationOptions.meetingCancellation">Download Votos Conta e
            Ordem</button>
        </div>
        <div class="col-md-3 text-center" *ngIf="!!votationOptions?.canUploadVotesFile">
          <button id="btnUpload" (click)="openModalInfo()" data-toggle="modal" data-target="#modal-upload-votes"
            class="btn btn-success btn-block"
            [disabled]="!votationOptions.currentMeeting || votationOptions.meetingCancellation">Upload Votos Conta e
            Ordem</button>
        </div>
      </div>
      <div class="card-body card-color">
        <table class="table">
          <thead>
            <tr>
              <td>código conta e ordem</td>
              <td>votação</td>
              <td>data votação</td>
              <td>comprovante</td>
              <td>procuração</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let contaOrdem of this.contaOrdens">
              <td>{{ contaOrdem.CodCo }}</td>
              <td><button (click)="vote(contaOrdem.CodCo)" class="btn btn-success"
                  [disabled]="!votationOptions.currentMeeting || votationOptions.meetingCancellation || !this.canVote">Votar</button>
              </td>
              <td *ngIf="contaOrdem.extendedProperties.MeetingParticipation">{{
                contaOrdem.extendedProperties.MeetingParticipation.createdOn | date:"dd/MM/yyyy":'-0600' }}</td>
              <td *ngIf="!contaOrdem.extendedProperties.MeetingParticipation">-</td>
              <td
                *ngIf="contaOrdem.extendedProperties.MeetingParticipation && contaOrdem.extendedProperties.MeetingParticipation.ProofParticipation">
                <a target="_blank"
                  (click)="openFile(contaOrdem.extendedProperties.MeetingParticipation.ProofParticipation)"
                  class="btn btn-success">Baixar</a>
              </td>
              <td
                *ngIf="!contaOrdem.extendedProperties.MeetingParticipation || !contaOrdem.extendedProperties.MeetingParticipation.ProofParticipation">
                -</td>
              <td *ngIf="contaOrdem.extendedProperties.MeetingParticipation"><a
                (click)="openFile(contaOrdem.extendedProperties.MeetingParticipation.ProcurationFile)"
                  class="btn btn-success"
                  [class.disabled]="!contaOrdem.extendedProperties.MeetingParticipation.ProcurationFile"
                  target="_blank">Baixar</a></td>
              <td *ngIf="!contaOrdem.extendedProperties.MeetingParticipation">-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row-7 d-flex justify-content-center" *ngIf="isLoading">
    <div class="spinner-border" role="status">
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modal-upload-votes" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          Upload Votos Conta e Ordem
        </h4>
      </div>
      <div class="modal-body" style="padding: 2rem;">
        <div class="accordion" id="js_demo_accordion-2">
          <div class="card">
            <div class="card-header" data-toggle="collapse" data-target="#js_demo_accordion-2a" aria-expanded="true">
              <span class="card-title">
                Upload de arquivos<i class="bi bi-info-circle-fill float-right" data-toggle="modal"
                  data-target="#modal-upload-votes-info"></i>
              </span>
            </div>
            <div id="js_demo_accordion-2a" class="collapse show" data-parent="#js_demo_accordion-2">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="form-group mb-0">
                      <label class="form-label">Votos</label>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="voteFile" accept=".csv"
                          (change)="onFileSelected($event)">
                        <label class="custom-file-label" for="voteFile">Escolher arquivo</label>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mb-0">
                      <label class="form-label">Procuração</label>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="procurationFile" accept=".pdf, .zip"
                          (change)="onFileSelected($event)" [disabled]="!this.hasVotesFile">
                        <label class="custom-file-label" for="procurationFile">Escolher arquivo</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" data-toggle="collapse" data-target="#js_demo_accordion-2b" aria-expanded="false"
              #validationsCard>
              <span class="card-title collapsed">
                Validação dos arquivos
              </span>
            </div>
            <div id="js_demo_accordion-2b" class="collapse" data-parent="#js_demo_accordion-2">
              <div class="card-body">
                <table class="table m-0 table-bordered table-hover" id="inconsistency-table">
                  <thead>
                    <tr>
                      <th class="text-center">Arquivo</th>
                      <th class="text-center">Inconsistências</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let file of this.inconsistencyFiles">
                      <td>{{file.name}}</td>
                      <td class="text-center"><a *ngIf="!file.success else successFile" href="{{file.url}}"
                          target="_blank">Baixar</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" #cancelModal>Cancelar</button>
        <button type="button" class="btn btn-primary" [disabled]="!isValid" (click)="saveVotes()">Salvar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal-upload-votes-info" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Orientações de preenchimento</h5>
        <button type="button" (click)="closeModalInfo()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Instruções para realizar o preenchimento correto da planilha de Lançamento de
           Votos, extraída através do botão "Download Votos Conta e Ordem":</p>
        <p class="dot">• Nas colunas das Deliberações (1 Deliberacao, 2 Deliberacao...N), os votos
          devem ser preenchidos por Conta e Ordem, com uma das
          palavras <strong>APROVO, REPROVO ou ABSTENHO</strong>, de acordo com a opção de
          voto;</p>
        <p class="dot">• Não devem ser inseridos caracteres especiais, espaço entre as letras,
          espaços no começo ou final das palavras, mais de uma palavra (mesmo que estejam corretas) dentro da mesma
          célula da planilha (ex.: APROVO REPROVO) ou palavras diferentes das
          citadas (ex.: aprovado, sim, não, X, reprovei, concordo, etc...);</p>
        <p class="dot">• Ao realizar o lançamento de voto para um código Conta e Ordem, devem
          ser preenchidas todas as Deliberações (quantas houverem) para o
          mesmo Conta e Ordem nenhuma pode ser deixada em branco;</p>
        <p class="dot">• Caso um código Conta e Ordem não tenha se manifestado, na planilha, a linha do
          código conta e ordem deve ser mantida e suas deliberações podem ficar sem
          nenhum preenchimento;</p>
        <p class="dot">• As colunas do arquivo não devem ser alteradas e nem os códigos Conta
          e Ordem;</p>
        <p class="dot">• A extensão do arquivo (.csv) não devem ser alterada;</p>
        <p>• Após o preenchimento da planilha com os votos, deve ser realizado o seu upload
           juntamente a Procuração (documento obrigatório), ambos em seus respectivos
           campos, para que sejam registrados no sistema.</p>
        <p>Seguem as orientações abaixo para o upload dos arquivos:</p>
        <p class="dot">&emsp;&emsp;• Ao clicar no botão "Upload Votos Conta e Ordem", abrirá uma caixa com 2
          campos, um para o upload da planilha de votos e outro para o upload da
          procuração (campo obrigatório);</p>
        <p class="dot">&emsp;&emsp;• Para o upload da planilha de Votos, o arquivo deve ser subido no campo
          <b>Votos</b>;
        </p>
        <p>Para realizar o upload da <b>Procuração:</b></p>
        <p class="dot">• O arquivo de Procuração deve estar no formato <b>.pdf</b>. Caso seja subido somente 1
          arquivo no campo, ele será atribuído para todos os Conta e Ordens inseridos na
          planilha de Lançamento de Votos, ou;</p>
        <p class="dot">• Para o upload de Procurações específicas de cada cotista Conta e Ordem que
          manifestou voto, salvar cada arquivo de procuração no formato .pdf, nomeados
          com o "código_do_conta_ordem.pdf" sem espaços. Exemplo: "1234.pdf." e
          compactar todos em um mesmo arquivo .Zip (nome à sua escolha).</p>
        <p class="dot">• Dentro da pasta compactada(.zip) deverá conter os pdfs correspondente de cada código conta e
          ordem
          cujas manifestação de voto foram inseridas no arquivo de votos (.csv)</p>
        <p class="dot">• Em casos de números de arquivos divergentes, ou conta e ordem
          inexistente será disponibilizado na caixa de Upload, um arquivo para
          Baixar, contendo as inconsistências descritas, para correção. Após as devidas correções no arquivo,
          atualize a página do navegador e suba novamente o arquivo de votos para garantir a leitura do arquivo novo.
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #successFile>
  <span>{{'-'}}</span>
</ng-template>
