import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

export function dateValidator(control: AbstractControl): { [key: string]: any } | null {
    if (control.value !== undefined && control.value !== null) {
        if (control.value < today()) {
            return { dateValidador: true };
        }
    }
    return null;
}

export function today(): string {
    const date = new Date();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return (year + '-' + month + '-' + day);
}

@Directive({
    selector: '[dateValidatorDirective]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: AppDateValidatorDirective,
        multi: true
    }]
})
export class AppDateValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } | null {
        return dateValidator(control);
    }
}

