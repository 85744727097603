import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from 'src/app/app.error-handler';
import { environment } from 'src/environments/environment';
import { WarningLevel } from '../../framework/enums';
import { IEnvelope } from '../../framework/interfaces';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({
    providedIn: 'root',
})
export class FileService {
    private URL = `${environment.baseApiUrl}/file`;
    private currentChunkStartByte = 0;
    private currentChunkFinalByte = 0;
    private chunkSize = 1000000;
    private chunkIndex = 0;
    constructor(private http: HttpClient) {
    }
    async convertToBase64(azurePath: string): Promise<any> {
        return this.http.get(`${this.URL}/convert?azurePath=${azurePath}`).toPromise()
            .then((response: any) => response.content);
    }
    async getUrlSafe(authToken: string, azurePath: string): Promise<any> {
        const queryString = new URLSearchParams();
        queryString.append('authToken', authToken);
        queryString.append('azurePath', azurePath);
        return this.http.get(`${this.URL}/url-safe?${queryString}`).toPromise()
            .then((response: any) => response.content);
    }
    public async remove(authToken: string, id: string): Promise<boolean> {
        const queryString = new URLSearchParams();
        queryString.append('authToken', authToken);
        queryString.append('identifier', id);
        const result = await this.rollbackChunkedFile(queryString.toString()).toPromise()
            .then(_ => {
                return true;
            })
            .catch(error => { throwError(error); return false; });
        return result;
    }
    public upload(file: File): Promise<any> {
        this.currentChunkFinalByte = this.chunkSize > file.size ? file.size : this.chunkSize;
        this.currentChunkStartByte = 0;
        this.chunkIndex = 0;
        // const uploadedFile = await this.uploadFile(file, Guid.create().toString()).then(_ => _)
        //     .catch(error => { throw new Error(error); });
        // return uploadedFile;
        return this.uploadFile(file, Guid.create().toString()).then((uploadedFile: any) => {
            return new Promise((resolve, reject) => {
                resolve(uploadedFile);
            });
        })
            .catch(error => throwError(error));
    }

    public async merge(azurePaths: string[]): Promise<string> {
        const body = {
            authToken: localStorage.getItem('token'),
            azurePaths
        };

        const envelope: IEnvelope<any> = {
            content: body,
            message: '',
            pageCount: 0,
            pageIndex: 0,
            pageSize: 0,
            totalCount: 0,
            warningLevel: WarningLevel.Info
        };

        return await this.http.post<IEnvelope<string>>(`${this.URL}/merge`, envelope, httpOptions)
            .toPromise()
            .then((response: IEnvelope<string>) => {
                if (response.warningLevel === WarningLevel.Success) {
                    return Promise.resolve(response.content);
                }
                return Promise.resolve('');
            });
    }

    private uploadFile(file: File, id: string): Promise<any> {
        const chunk: Blob = file.slice(this.currentChunkStartByte, this.currentChunkFinalByte);
        const remainingBytes = file.size - this.currentChunkFinalByte;
        let queryString: URLSearchParams;
        let formData: FormData;
        if (this.currentChunkFinalByte === file.size) {
            queryString = new URLSearchParams();
            queryString.append('authToken', localStorage.getItem('token'));
            formData = new FormData();
            formData.append('uuid', id.toString());
            formData.append('chunkIndex', this.chunkIndex.toString());
            formData.append('totalFileSize', file.size.toString());
            formData.append('currentChunkSize', chunk.size.toString());
            formData.append('totalChunkCount', (this.chunkIndex + 1).toString());
            formData.append('chunkByteOffset', (this.chunkIndex * chunk.size).toString());
            formData.append('chunkSize', this.chunkSize.toString());
            formData.append('authToken', localStorage.getItem('token'));
            formData.append('fileName', file.name);
            formData.append('file', chunk, file.name);

            return this.chunkUpload(formData, queryString.toString()).toPromise()
                .then(_ => {
                    return new Promise((resolve, reject) => {
                        queryString = new URLSearchParams();
                        queryString.append('identifier', id.toString());
                        queryString.append('fileName', file.name);
                        queryString.append('size', file.size.toString());
                        queryString.append('count', '1');
                        queryString.append('authToken', localStorage.getItem('token'));
                        formData = new FormData();
                        formData.append('identifier', id.toString());
                        formData.append('fileName', file.name);
                        formData.append('size', file.size.toString());
                        formData.append('count', '1');
                        formData.append('authToken', localStorage.getItem('token'));
                        resolve(formData);
                    });
                }).then((formData: any) => {
                    return this.commitChunkedFile(formData, queryString.toString()).toPromise();
                }).then((response: any) => {
                    return {
                        id,
                        name: file.name,
                        azurePath: response
                    };
                })
                .catch(error => {
                    throwError(error);
                });
        } else if (remainingBytes < this.chunkSize) {
            this.currentChunkStartByte = this.currentChunkFinalByte;
            this.currentChunkFinalByte = this.currentChunkStartByte + remainingBytes;
            queryString = new URLSearchParams();
            queryString.append('authToken', localStorage.getItem('token'));
            formData = new FormData();
            formData.append('uuid', id.toString());
            formData.append('chunkIndex', this.chunkIndex.toString());
            formData.append('totalFileSize', file.size.toString());
            formData.append('currentChunkSize', chunk.size.toString());
            formData.append('totalChunkCount', (this.chunkIndex + 1).toString());
            formData.append('chunkByteOffset', (this.chunkIndex * chunk.size).toString());
            formData.append('chunkSize', this.chunkSize.toString());
            formData.append('authToken', localStorage.getItem('token'));
            formData.append('fileName', file.name);
            formData.append('file', chunk, file.name);

            return this.chunkUpload(formData, queryString.toString()).toPromise()
                .then(_ => {
                    this.chunkIndex += 1;
                    return this.uploadFile(file, id);
                })
                .catch(error => {
                    throwError(error.message);
                });
        }
        else {
            this.currentChunkStartByte = this.currentChunkFinalByte;
            this.currentChunkFinalByte = this.currentChunkStartByte + this.chunkSize;
            queryString = new URLSearchParams();
            queryString.append('authToken', localStorage.getItem('token'));
            formData = new FormData();
            formData.append('uuid', id.toString());
            formData.append('chunkIndex', this.chunkIndex.toString());
            formData.append('totalFileSize', file.size.toString());
            formData.append('currentChunkSize', chunk.size.toString());
            formData.append('totalChunkCount', (this.chunkIndex + 1).toString());
            formData.append('chunkByteOffset', (this.chunkIndex * chunk.size).toString());
            formData.append('chunkSize', this.chunkSize.toString());
            formData.append('authToken', localStorage.getItem('token'));
            formData.append('fileName', file.name);
            formData.append('file', chunk, file.name);

            return this.chunkUpload(formData, queryString.toString()).toPromise()
                .then(_ => {
                    this.chunkIndex += 1;
                   return this.uploadFile(file, id);
                })
                .catch(error => {
                    throwError(error.message);
                });
        }
    }
    private chunkUpload(model: any, queryString: string): Observable<any> {
        return this.http.post(`${this.URL}/chunk/upload?${queryString}`, model)
            .pipe(catchError(ErrorHandler.handleError));
    }
    private commitChunkedFile(model: any, queryString: string): Observable<any> {
        return this.http.put(`${this.URL}/commit?${queryString}`, model, { responseType: 'text' })
            .pipe(catchError(ErrorHandler.handleError));
    }
    private rollbackChunkedFile(queryString: string): Observable<any> {
        return this.http.delete(`${this.URL}/rollback?${queryString}`, { responseType: 'text' })
            .pipe(catchError(ErrorHandler.handleError));
    }
}

export interface IFileUploaded {
  id: string;
  name: string;
  azurePath: string;
}
