<!-- <nav *ngIf="votationOptions && votationOptions.scope" class="navbar navbar-expand-lg navbar-light" [style.background-color]="votationOptions.scope.baseColor">
    <a class="navbar-brand" href="#">
        <img [src]="votationOptions.scope.brandImageUrl" alt="">
    </a>
</nav> -->
<div class="row h-100 d-flex justify-content-center align-items-center">
    <div class="col-10" *ngIf="!isLoading">
        <div style="text-align: left;">
            <i (click)="exit()" class="bi bi-box-arrow-left icon-out" style="font-size: 2rem;"></i>
        </div>
        <div class="card space">
            <div class="card-header" [style.background-color]="votationOptions.scope.baseColor">
                <img [src]="votationOptions.scope.brandImageUrl" class="card-header-logo logo" alt="">
            </div>
            <h5 class="card-header">
                {{votationOptions?.fund}}
            </h5>
            <div class="row space" *ngIf="messageValidation">
                <div class="col text-center">
                    <span class="text-warning">{{votationOptions.messageValidation}}</span>
                </div>
            </div>
            <div class="row space">
                <div class="col text-center">
                    <h6>{{votationOptions.meetingName}}</h6>
                </div>
            </div>
            <div class="row space">
                <div class="col">
                    <button type="button" class="btn btn-link float-right" (click)="this.download()"
                        [hidden]="!this.votationOptions.additionalDocuments">Documentos
                        complementares <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        [hidden]="!isDownloading"></span></button>
                </div>
            </div>
            <div class="card-body card-color">
                <app-vote-item #voteItem [form]="this.form" [votationOptions]="votationOptions"
                    [hiddeAttach]="hiddeAttach" [uploadLimited]="uploadLimited"></app-vote-item>
                <div class="row space" *ngIf="this.urlHasCodCo()">
                    <div class="col">
                        <input class="form-control" type="file" name="formFileProcuration" id="formFileProcuration" (change)="onFileSelected($event)"
                            hidden="true">
                        <button type="button" class="btn btn-secondary" onclick="$('#formFileProcuration').click()">
                            <span>Anexar procuração</span>
                        </button>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            [hidden]="!this.uploading"></span>
                        <label style="padding-left: 5px;" *ngIf="procurationFile">{{ procurationFile.name }}</label>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col">
                        <button id="btnSave" type="button" class="btn btn-success btn-block" (click)="save()"
                            [disabled]="invalidState || isSaving || !votationOptions.currentMeeting || 
                            votationOptions.meetingCancellation || uploading || (this.votationComputed && this.urlHasCodCo())">
                            {{textVotation}} 
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" [hidden]="!isSaving"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row-7 d-flex justify-content-center" *ngIf="isLoading">
        <div class="spinner-border" role="status">
        </div>
    </div>
    <app-acceptance-term></app-acceptance-term>
</div>
