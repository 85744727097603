<div id="acceptance-term" class="modal" tabindex="-1">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span> TERMO DE ACEITE DE
                        VOTO ELETRÔNICO PARA
                        ASSEMBLEIA DE FUNDOS DE INVESTIMENTOS </span>
                    <p
                        [hidden]="!hasSecondAcceptanceTerm">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;______________________________________________________________________________________</p>
                    <span [hidden]="!hasSecondAcceptanceTerm">
                        TERM OF
                        ACCEPTANCE OF ELECTRONIC
                        VOTING FOR INVESTMENT FUND MEETING
                    </span>
                </h5>
            </div>
            <div class="modal-body">
                <div id="acceptance-term-body"
                    class="col-md-12 col-sm-12 col-xl-12"></div>
                <div [hidden]="!hasSecondAcceptanceTerm"
                    class="col-md-12 col-sm-12 col-xl-12">
                    <br>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;____________________________________________________________________________________________________________________________________________</p>
                    <br>
                    <div id="second-acceptance-term-body"></div>
                </div>

            </div>
            <div class="modal-footer">
                <table>
                    <tr>
                        <td>
                            <div class="col-md-12 col-sm-12 col-xl-12">
                                <input type="checkbox" value
                                    (change)="changeCheckbox($event)"
                                    id="defaultCheck1">&nbsp;&nbsp;
                                <label for="defaultCheck1">
                                    Li e concordo com o termo
                                </label>
                                <p
                                    class="text-danger">{{validationMessage}}</p>
                            </div>
                            <div [hidden]="!hasSecondAcceptanceTerm" class="col-md-12 col-sm-12 col-xl-12">
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;______________________________________________________________________________________</p>
                                <br>
                                <input type="checkbox" value
                                    (change)="changeCheckbox($event)"
                                    id="defaultCheck2">&nbsp;&nbsp;
                                <label for="defaultCheck2">
                                    I have read and agree with the term
                                </label>
                                <p
                                    class="text-danger">{{secondValidationMessage}}</p>
                            </div>
                        </td>
                    </tr>
                </table>
                <button type="button" class="btn btn-primary"
                    (click)="saveTermAnswer(true)"
                    [disabled]="checkboxSelected">Aceitar</button>
                <button type="button" class="btn btn-secondary"
                    (click)="saveTermAnswer(false)">Recusar</button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" id="acceptance-term-loader">
    <div class="spinner-border" role="status">
    </div>
</div>