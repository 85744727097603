import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../../../app.error-handler';
import { environment } from '../../../../environments/environment';
import * as FW from '../../../framework/core';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()
export class VoteService {

    constructor(private http: HttpClient) { }

    public authenticate(model: any, documentNumberLogin: string): Observable<any> {
        model.documentNumberLogin = documentNumberLogin;
        return this.http.post<any>(`${environment.baseApiUrl}/vote/authentication`, new FW.Envelope(model), httpOptions)
            .pipe(catchError(ErrorHandler.handleError));
    }

    public downloadAdditionalDocuments(token: string, meeting: string): Observable<any> {
        const model = {
            token,
            meeting
        };

        return this.http.post(`${environment.baseApiUrl}/vote/downloadAdditionalDocuments`, new FW.Envelope(model), httpOptions)
            .pipe(catchError(ErrorHandler.handleError));
    }

    public save(model: any): Observable<any> {
        return this.http.post(`${environment.baseApiUrl}/vote/save`, new FW.Envelope(model))
            .pipe(catchError(ErrorHandler.handleError));
    }
}
