import { Component, OnInit } from '@angular/core';
import { isNullOrEmpty } from '../../../framework/core';
import { AcceptanceTermAnswer } from '../models/acceptance-term-answer.model';
import { AcceptanceTermVerification } from '../models/acceptance-term-verification.model';
import { VoteAuthentication } from '../models/vote-authentication.model';
import { AcceptanceTermService } from '../services/acceptanceTerm.service';

declare var $: any;
@Component({
    selector: 'app-acceptance-term',
    templateUrl: 'acceptance-term.component.html',
    styleUrls: ['./acceptance-term.component.scss']
})
export class AcceptanceTermComponent implements OnInit {
    constructor(private acceptanceTermService: AcceptanceTermService) { }
    ngOnInit(): void {
        if (localStorage.getItem('short')) {
            this.hasAcceptedTerm();
            this.loadTermText();
            this.loadSecondTermText();
        }
    }

    termAccepted: boolean;
    hasSecondAcceptanceTerm: boolean = false;
    checkboxSelected: boolean = true;
    isPageLoading: boolean = false;
    validationMessage: string;
    secondValidationMessage: string;

    get isLoading(): boolean {
        return this.isPageLoading;
    }

    changeCheckbox(event) {
        if (event.target.checked) {
            this.checkboxSelected = false;
            this.validationMessage = '';
            this.secondValidationMessage = '';
        }
        else {
            this.checkboxSelected = true;
            this.validationMessage = 'É necessário aceitar o termo para prosseguir';
            this.secondValidationMessage = 'You must accept the term to proceed';
        }
    }

    // get isLoading(): boolean {
    //     return false;
    // }

    private verifyParams(): boolean {
        return !isNullOrEmpty(localStorage.getItem('token')) &&
            !isNullOrEmpty(localStorage.getItem('meeting'));
    }

    private getParams(): AcceptanceTermVerification {
        if (!this.verifyParams()) {
            return null;
        }

        return new AcceptanceTermVerification(
            localStorage.getItem('token'),
            localStorage.getItem('meeting'),
            localStorage.getItem('documentNumberLogin'));
    }

    private getParamsSaveAnswer(accepted) {
        if (!this.verifyParams()) {
            return null;
        }

        return new AcceptanceTermAnswer(
            localStorage.getItem('token'),
            localStorage.getItem('meeting'),
            accepted,
            localStorage.getItem('documentNumberLogin')
        );
    }

    private hasAcceptedTerm() {
        ;
        this.acceptanceTermService.hasAcceptedTerm(this.getParams()).toPromise()
            .then(response => {
                this.termAccepted = response.content;
                if (!this.termAccepted) {
                    $('#acceptance-term').modal({ backdrop: 'static', keyboard: false });
                    $('#acceptance-term').modal('show');
                    this.isPageLoading = false;
                } else {
                    $('#acceptance-term').modal('hide');
                    this.isPageLoading = false;
                }
            })
            .catch(error => console.log(error));
    }

    private loadTermText() {
        this.acceptanceTermService.loadTermText(this.getParams()).toPromise()
            .then(response => {
                $('#acceptance-term-body').html(response.content);
            })
            .catch(error => console.log(error));
    }

    private loadSecondTermText() {
        this.acceptanceTermService.loadSecondTermText(this.getParams()).toPromise()
            .then(response => {
                ;
                if (response.content) {
                    this.hasSecondAcceptanceTerm = true;
                    $('#second-acceptance-term-body').html(response.content);
                } else {
                    this.hasSecondAcceptanceTerm = false;
                }
            })
            .catch(error => console.log(error));
    }

    public saveTermAnswer(accepted) {
        this.validationMessage = '';
        this.secondValidationMessage = '';

        if (accepted) {
            this.isPageLoading = true;
            this.acceptanceTermService.saveTermAnswer(this.getParamsSaveAnswer(accepted)).toPromise()
                .then(response => {
                    $('#acceptance-term').modal('hide');
                    this.isPageLoading = false;
                })
                .catch(error => console.log(error));
        } else {
            this.validationMessage = 'É necessário aceitar o termo para prosseguir';
            this.secondValidationMessage = 'You must accept the term to proceed';
        }
    }
}