export class Votation {
    public meetingCancellation: string;
    public meeting: string;
    public currentMeeting: boolean;
    public messageValidation: string;
    public fund: string;
    public preamble: string;
    public additionalDocuments: boolean;
    public participant: Participant;
    public deliberations: Deliberation[];
    public scope: Scope;
}

export class Participant {
    public id: string;
    public canVote: boolean;
    public personType: string;
    public particularityCode: string;
    public documentNumber: string;
    public representatives: Array<Representative>;
}

export class Representative {
    public id: string;
    public documentNumber: string;
}

export class Deliberation {
    public deliberation: string;
    public text: string;
    public index: number;
    public options: DeliberationOption[];    
    public commentType: number;
    public showComment: boolean;
    public sizeCommentText: number;
    public commentText: string;


}

export class DeliberationOption {
    public id: string;
    public text: string;
    public value: number;
    public isChecked: boolean;
}

export class Scope {
    brandImageUrl: string;
    baseColor: string;
}

export enum CommentType {
    YES_OPTIONAL = 0,
    YES_MANDATORY = 1,
    NO = 2,
  }