<div class="row">
    <div class="col-12">
        <!-- <span class="card-title ">{{votationOptions.preamble}}</span> -->
        <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(votationOptions.preamble)"></div>
    </div>
</div>
<div class="row space" *ngFor="let deliberation of votationOptions.deliberations">
    <div class="col">
        <div class="shadow-lg p-3 mb-5 bg-body rounded">
            <div class="row">
                <div class="col">
                    <span class="fs-6">Deliberação {{deliberation.index}}</span>
                </div>
            </div>
            <div class="row space">
                <div class="col">
                    <!-- <span>{{deliberation.text}}</span> -->
                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(deliberation.text)"></div>
                </div>
            </div>
            <div class="row space">
                <div class="col" *ngIf="votationOptions">
                    <app-vote-item-option [deliberation]="deliberation" [canVote]="canVote" [form]="this.form">
                    </app-vote-item-option>
                </div>
            </div>

            <div class="row space">
                <div class="col" *ngIf="deliberation.showComment">
                    <app-vote-item-comment [deliberation]="deliberation" [canVote]="canVote" [form]="this.form">
                    </app-vote-item-comment>
                </div>
            </div>
            <!-- <div class="row space">
                <div class="col">
                    <span class="fs-6">Comentário {{deliberation.comment}}</span>
                </div>
            </div>
            <div class="row space">
                <div class="col">

                    <textarea type="text" class="form-control shadow-inset-2"
                        id="commentTextarea"
                        placeholder="Insira aqui o comentário da deliberação {{deliberation.index}}"
                        (keyup)="countChar(deliberation.id)"

                        maxlength="8000"></textarea>
                    <div class="col-sm-12 col-md-12 d-flex align-items-center justify-content-end mb-g">
                        {{deliberation.sizeText}} / 8000</div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <input class="form-control" type="file" name="formFile" id="formFile" (change)="onFileSelected($event)" accept=".pdf"
            hidden="true">
        <button type="button" class="btn btn-secondary float-right" (click)="this.attach()" [hidden]="hiddeAttach"
            [disabled]="isUploading || maxNumberUpload">
            <span>Anexar documento comprobatório
                <span class="badge bg-danger" [hidden]="isUploading">{{uploadedFiles.length}}</span>
            </span>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                [hidden]="!isUploading"></span>
        </button>
    </div>
</div>
<div class="row">
    <div class="col">

        <button type="button" class="btn btn-link float-right" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
            [hidden]="uploadedFiles.length === 0" style="margin-top: 5px;">Anexos</button>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Anexos</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let file of this.uploadedFiles">
                            <td scope="row">{{file.name}}</td>
                            <td class="text-center">
                                <i class="bi bi-eye text-primary icon-pointer"
                                    (click)="openFile(file.azurePath)"
                                    style="font-size: 20px;padding-right: 15px;text-decoration: none!important;"></i>
                                <i class="bi bi-trash icon-trash" (click)="removeFile(file.id)"
                                    style="font-size: 1.2rem; color: red;"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
