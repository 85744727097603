export class VoteAuthentication{
    token: string;
    meeting: string;
    codCo: string;

    constructor(
        token: string,
        meeting: string,
        codCo: string
    ) {
        this.token = token;
        this.meeting = meeting;
        this.codCo = codCo;
    }
}
