import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isNullOrBlank, isNullOrEmpty } from 'src/app/framework/core';
import { FileService } from 'src/app/services/application/file.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { dateValidator } from 'src/app/validators/date-validator';
import { CompanyRepresentative } from '../models/company-representative.model';
declare var $: any;
declare var window: any;
@Component({
  selector: 'app-company-representative-item',
  templateUrl: './company-representative-item.component.html',
  styleUrls: ['./company-representative-item.component.scss']
})
export class CompanyRepresentativeItemComponent implements OnInit {

  @ViewChild('formFile') formFile;

  @Input() form: FormGroup;
  @Input() currentMeeting: boolean;
  @Input() canIndicate: boolean;
  @Input() quantityFilesAttach: number;

  uploadedFiles: any[] = [];

  private _uploading: boolean;
  private _attempt = false;
  private _index = 0;

  constructor(
    private fb: FormBuilder,
    private fileService: FileService,
    private messageService: MessageSwalService) { }

  ngOnInit(): void {
    const formArray = (this.form.get('representatives') as FormArray);
    if (formArray === null || formArray.length === 0) {
      this.form.addControl('representatives', this.fb.array([this.createItem()]));
    } else if (this.acceptsMultipleFiles()) {
      (this.form.get('representatives') as FormArray).controls.forEach(async control => {
        const attachment = control.get('attachment')?.value;
        if (!!attachment) {
          await this.generateAzurePath(control, attachment);
          const index = (this.form.get('representatives') as FormArray).controls.indexOf(control);
          (this.form.get('representatives') as FormArray).controls[index].patchValue(control);
        }
      });
    }
    this._uploading = false;
  }

  get isSaving(): boolean {
    return false;
  }

  get isUploading(): boolean {
    return this._uploading;
  }

  get numberIndications(): number {
    const formArray = (this.form.get('representatives') as FormArray);
    if (formArray.length > 0) {
      const representatives = (formArray.getRawValue() as CompanyRepresentative[]);
      return representatives.filter(representative => representative.selected).length;
    }
    return 0;
  }

  verifySelection(index: number): number {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    if (this.acceptsMultipleFiles() && control.get('attachments')) {
      return (control.get('attachments') as FormArray).length;
    }
    else if (!control.get('procurationAzurePath')) {
      return 0;
    }
    return isNullOrEmpty(control.get('procurationAzurePath').value) ? 0 : 1;
  }

  add(index: number): void {
    if (!this.currentMeeting) {
      return;
    }
    const control = (this.form.get('representatives') as FormArray).controls[index];
    this._attempt = true;
    if (control.invalid) {
      return;
    }

    (this.form.get('representatives') as FormArray).push(this.createItem());
    this._attempt = false;
  }

  canAdd(isLast: boolean): boolean {
    return isLast;
  }

  indicate(index: number): void {
    if (!this.currentMeeting || !this.canIndicate) {
      return;
    }
    const control = (this.form.get('representatives') as FormArray).controls[index];
    const value = !control.get('selected').value;
    this._attempt = true;
    if (control.invalid) {
      return;
    }
    else if (this.form.get('representation').value === '1' && this.numberIndications > 0 && value) {
      return;
    } else if (this.form.get('representation').value === '2' && this.numberIndications > 1 && value) {
      return;
    }
    control.get('selected').setValue(value);
    (this.form.get('representatives') as FormArray).controls[index].patchValue(control);
    this._attempt = false;
  }

  isSelected(index: number): boolean {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    return control.get('selected').value;
  }

  remove(index: number): void {
    if (!this.currentMeeting) {
      return;
    }
    const control = (this.form.get('representatives') as FormArray).controls[index];
    if (isNullOrEmpty(control.get('id').value)) {
      (this.form.get('representatives') as FormArray).removeAt(index);
    } else {
      control.get('excluded').setValue(true);
      control.get('selected').setValue(false);
      (this.form.get('representatives') as FormArray).controls[index].patchValue(control);
    }
  }

  canRemove(index: number): boolean {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    return index > 0 || ((this.form.get('representatives') as FormArray).length > 1 && !isNullOrEmpty(control.get('id').value));
  }

  public verifyInvalid(index: number): boolean {
    if (!this.currentMeeting) {
      return true;
    }
    const control = (this.form.get('representatives') as FormArray).controls[index];
    return control.invalid;
  }

  public attach(index: number): void {
    this._index = index;
    this.formFile.nativeElement.click();
  }

  checkInvalidity(index: number, nameControl: string): boolean {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    if (nameControl === 'procurationEndDate') {
      if (control.get(nameControl).invalid && control.get(nameControl).errors.dateValidador &&
        (!isNullOrBlank(control.get('id').value) || this._attempt)) {
        return true;
      }
    }
    return this._attempt && control.get(nameControl).invalid;
  }

  checkAttachmentInvalidity(index: number): boolean {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    if (!this._attempt) {
      return false;
    } else if (!control.get('procurationAzurePath')) {
      return false;
    }
    return isNullOrEmpty(control.get('procurationAzurePath').value);
  }

  public onFileSelected(event: any): void {
    this._uploading = true;
    const file = event.target.files[0];

    if (!file.type.includes('pdf')) {
      this.messageService.displayMessage({ warninglevel: 1, message: 'Extensão inválida, selecione um arquivo pdf.' }, () => { });
      this._uploading = false;
      return;
    }

    this.fileService.upload(file).then(async uploadedFile => {
      this._uploading = false;

      if (uploadedFile == null) {
        this.messageService.displayMessage({ warninglevel: 1, message: 'Formato de arquivo não aceito!' }, () => { });
        return;
      }
      else {
        const control = (this.form.get('representatives') as FormArray).controls[this._index];
        control.get('attachment')?.setValue(uploadedFile);

        const azurePath = await this.generateAzurePath(control, uploadedFile);

        control.get('procurationAzurePath')?.setValue(azurePath);
        (this.form.get('representatives') as FormArray).controls[this._index].patchValue(control);
        $('#formFile').val('');
      }
    }).catch(error => {
      this._uploading = false;
      if (error.message !== 'Cannot read property \'size\' of undefined') {
        this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
      }
    });
  }

  async removeFile(id: string): Promise<void> {
    await this.fileService.remove(localStorage.getItem('token'), id)
      .then(async _ => {
        this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== id);
        const formArray = (this.form.get('representatives') as FormArray);
        if (formArray.length > 0) {
          const control = formArray.controls[this._index];
          control.get('procurationAzurePath').setValue('');
          control.get('attachment').get('id').setValue('');
          control.get('attachment').get('name').setValue('');
          control.get('attachment').get('azurePath').setValue('');

          await this.removeFileWhenMultipleFiles(control, id);

          formArray.controls[this._index].patchValue(control);
        }
        return 0;
      })
      .catch(error => {
        this._uploading = false;
        this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
      });
  }

  viewAttachment(index: number): void {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    if (!control.get('attachment')) {
      return;
    }
    this._index = index;
    this.uploadedFiles = [];
    if (this.acceptsMultipleFiles() && control.get('attachments')) {
      this.viewAttachmentWhenMultipleFiles(control);
    } else {
      this.uploadedFiles.push(control.get('attachment').value);
    }
  }

  canEdit(index: number): boolean {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    return isNullOrBlank(control.get('id').value);
  }

  canEditProcurationEndDate(index: number): boolean {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    if (isNullOrBlank(control.get('id').value)) {
      return true;
    } else if (control.get('canEditProcurationEndDate').value) {
      return true;
    }
    return false;
  }

  canViewAttachment(index: number): boolean {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    if (this.verifySelection(index) === 0) {
      return false;
    }
    return isNullOrBlank(control.get('id').value);
  }

  canList(index: number): boolean {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    return control.get('excluded').value === false;
  }

  textRepresentative(index: number): string {
    const control = (this.form.get('representatives') as FormArray).controls[index];
    return `Dados do representante ${control.get('name').value}`;
  }

  viewFile(file: any): void {
    this.fileService.getUrlSafe(localStorage.getItem('token'), file.azurePath)
      .then(urlSafe => {
        window.openResourceLocalUrl(urlSafe, window.getMimeType(file.azurePath.toLowerCase()));
      });
  }

  private createItem(): FormGroup {
    const formGroup = this.fb.group({
      id: ['', []],
      name: ['', Validators.required],
      documentNumber: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      procurationEndDate: ['', [Validators.required, dateValidator]],
      attachment: this.fb.group({
        id: ['', []],
        name: ['', []],
        azurePath: ['', []]
      }),
      procurationAzurePath: ['', Validators.required],
      selected: [false, Validators.required],
      excluded: [false, []],
      canEditProcurationEndDate: [true, []]
    });

    if (this.acceptsMultipleFiles()) {
      formGroup.addControl('attachments', new FormArray([]));
    }

    return formGroup;
  }

  public today(): string {
    const date = new Date();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return (year + '-' + month + '-' + day);
  }

  private checkFileExisting(file: any): boolean {
    const formArray = (this.form.get('representatives') as FormArray);
    const representatives = (formArray.getRawValue() as any[]);
    const fileExisting = representatives.find(r => r.attachment.name === file.name);
    if (fileExisting && !isNullOrEmpty(fileExisting)) {
      return true;
    }

    if (this.acceptsMultipleFiles()) {
      return this.checkFileExistingWhenMultipleFiles(file.name);
    }

    return false;
  }

  public acceptsMultipleFiles = () => this.quantityFilesAttach ?? 0 > 0;

  private async generateAzurePath(representativeContol: AbstractControl, uploadedFile: any) {
    let azurePath = uploadedFile.azurePath;
    if (this.acceptsMultipleFiles()) {
      const attachments = (representativeContol.get('attachments') as FormArray);
      const newFile = new FormControl({
        id: uploadedFile.id,
        name: uploadedFile.name,
        azurePath: uploadedFile.azurePath
      }, null);
      attachments.controls.push(newFile);
      const azurePaths = attachments.controls
        .filter(c => !!c.value.azurePath)
        .map(c => c.value.azurePath);
      if (azurePaths.length > 1) {
        azurePath = await this.fileService.merge(azurePaths);
      }
    }

    return azurePath;
  }

  private async removeFileWhenMultipleFiles(representativeContol: AbstractControl, id: string) {
    if (this.acceptsMultipleFiles()) {
      const attachments = (representativeContol.get('attachments') as FormArray).controls
        .filter(c => !!c.value.azurePath && c.value.id !== id);
      const azurePaths = attachments
        .map(c => c.value.azurePath);

      if (!azurePaths || azurePaths.length <= 0) {
        (representativeContol.get('attachments') as FormArray).clear();
        return;
      }

      let azurePath = azurePaths[0];

      if (azurePaths.length > 1) {
        azurePath = await this.fileService.merge(azurePaths);
      }

      const attachment = {
        id: attachments[0].value.id,
        name: attachments[0].value.name,
        azurePath: attachments[0].value.azurePath
      };

      (representativeContol.get('attachments') as FormArray).controls = attachments;

      representativeContol.get('procurationAzurePath').setValue(azurePath);
      representativeContol.get('attachment')?.setValue(attachment);
    }
  }

  private viewAttachmentWhenMultipleFiles(representativeContol: AbstractControl) {
    if (this.acceptsMultipleFiles()) {
      const attachments = (representativeContol.get('attachments') as FormArray);
      attachments.controls.forEach(control => {
        this.uploadedFiles.push({
          id: control.value.id,
          name: control.value.name,
          azurePath: control.value.azurePath
        });
      });
    }
  }

  private checkFileExistingWhenMultipleFiles(fileName: string) {
    const formArray = (this.form.get('representatives') as FormArray);
    let existing = false;
    formArray.controls.forEach(representative => {
      const attachments = (representative.get('attachments') as FormArray);
      if (!!attachments && attachments.controls.findIndex(c => c.get('name')?.value === fileName) >= 0) {
        existing = true;
      }
    });

    return existing;
  }

}
