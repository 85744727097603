import { Envelope } from './../../framework/core';
import { FileService, IFileUploaded } from './../../services/application/file.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmpty } from '../../framework/core';
import { MessageSwalService } from '../../services/application/messageswal.service';
import { Votation } from '../vote/models/votation.model';
import { VoteAuthentication } from '../vote/models/vote-authentication.model';
import { VoteService } from '../vote/services/vote.service';
import { NavigationService } from '../vote/services/navigation.service';
import { MeetingParticipant } from './models/meeting-participant.model';
import { MeetingParticipantService } from './services/meetingParticipant.service';
import { WarningLevel } from '../../framework/enums';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare var $: any;
declare var window: any;

const typeFile = {
  CSV: 1,
  PDF: 2,
  Zip: 3
};

@Component({
  selector: 'app-conta-ordem',
  templateUrl: './conta-ordem.component.html',
  styleUrls: ['./conta-ordem.component.scss']
})
export class ContaOrdemComponent implements OnInit, OnDestroy {
  @ViewChild('cancelModal', { static: true }) cancelModal: ElementRef;
  @ViewChild('validationsCard', { static: true }) validationsCard: ElementRef;

  votationOptions: Votation;

  private contaOrdens: Array<MeetingParticipant>;
  private saving: boolean;
  private procurationFile: any;
  private votesFile: any;

  private readonly validateProcurationFile$: Subject<void> = new Subject();
  private readonly validateVoteFile$: Subject<void> = new Subject();
  private readonly unsubscribe$: Subject<void> = new Subject();

  public inconsistencyFiles: any[] = [];

  constructor(
    private meetingParticipantService: MeetingParticipantService,
    private navigationService: NavigationService,
    private messageService: MessageSwalService,
    private voteService: VoteService,
    private router: Router,
    private route: ActivatedRoute,
    private fileService: FileService) {

    if (!this.navigationService.authenticate) {
      localStorage.removeItem('short');
      localStorage.removeItem('documentNumber');
      localStorage.removeItem('documentNumberRepresentative1');
      localStorage.removeItem('documentNumberRepresentative2');
    }

    if (!isNullOrEmpty(this.route.snapshot.params.token) &&
      !isNullOrEmpty(this.route.snapshot.params.meeting)) {
      localStorage.setItem('token', this.route.snapshot.params.token);
      localStorage.setItem('meeting', this.route.snapshot.params.meeting);
    }
  }

  get isLoading(): boolean {
    return false;
  }

  get isValid(): boolean {
    return !!this.procurationFile && !!this.votesFile;
  }

  get hasVotesFile(): boolean {
    return !!this.votesFile;
  }

  get canVote(): boolean {
    if (this.votationOptions) {
      if (this.votationOptions.currentMeeting && this.votationOptions.participant.canVote) {
        return true;
      }
    }
    return false;
  }

  async ngOnInit(): Promise<void> {
    this.inconsistencyFiles = [];
    this.saving = false;
    if (this.verifyParams()) {
      let documentNumberLogin = this.route.snapshot.params.documentNumberLogin;

      if (!documentNumberLogin) {
        documentNumberLogin = window.btoa(localStorage.getItem('documentNumberLogin'));
      }

      await this.voteService.authenticate(this.getParams(), documentNumberLogin).toPromise()
        .then(response => {
          this.votationOptions = response.content;
          let shortOk = window.atob(localStorage.getItem('short'));
          if (this.route.snapshot.params.portal != null) { shortOk = 'OK'; }
          if (shortOk !== 'OK') {
            localStorage.removeItem('documentNumber');
            localStorage.removeItem('documentNumberRepresentative1');
            localStorage.removeItem('documentNumberRepresentative2');

            localStorage.setItem('documentNumber', this.votationOptions.participant.documentNumber);
            let index = 1;
            this.votationOptions.participant.representatives.forEach(representative => {
              localStorage.setItem(`documentNumberRepresentative${index}`, representative.documentNumber);
              index += 1;
            });

            this.router.navigate(['/conta-ordem-auth'], {state: response.content});
          } else {
            this.meetingParticipantService.listByDocument({
              documentNumber: this.votationOptions.participant.documentNumber,
              meeting: localStorage.getItem('meeting'),
              token: localStorage.getItem('token')
            }).toPromise().then(
              response => {
                this.contaOrdens = response.content;
              }
            );
          }
        })
        .catch(error => {
          this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
          this.router.navigate([`/not-found`]);
        });
    } else {
      localStorage.clear();
      this.router.navigate([`/not-found`]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.validateVoteFile$.next();
    this.validateVoteFile$.complete();

    this.validateProcurationFile$.next();
    this.validateProcurationFile$.complete();
  }

  private verifyParams(): boolean {
    return !isNullOrEmpty(localStorage.getItem('token')) &&
      !isNullOrEmpty(localStorage.getItem('meeting'));
  }

  private getParams(): VoteAuthentication {
    if (!this.verifyParams()) {
      return null;
    }

    return new VoteAuthentication(
      localStorage.getItem('token'),
      localStorage.getItem('meeting'),
      null);
  }

  public vote(codCo: string) {
    this.router.navigateByUrl(
      `/vote/` +
      encodeURIComponent(this.route.snapshot.params.token) + '/' +
      encodeURIComponent(this.route.snapshot.params.meeting) + '/' +
      encodeURIComponent(localStorage.getItem('documentNumberLogin') ?? '') + '/' +
      encodeURIComponent(codCo)
    );
  }

  public download(): void {
    this.meetingParticipantService
      .distributorDeliberations(
        this.route.snapshot.params.token,
        this.votationOptions.meeting,
        this.votationOptions.participant.id
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response.warningLevel === WarningLevel.Success) {
          window.open(response.content, '_blank');
        }
      });
  }

  public onFileSelected(event: any): void {
    const file = event.target.files[0];

    this.fileService.upload(file).then(uploadedFile => {
      if (uploadedFile == null) {
        this.messageService.displayMessage({
          warninglevel: 1,
          message: 'Formato de arquivo não aceito!'
        }, () => { });
        return;
      }
      if (event.target.id === 'procurationFile') {
        this.validateProcurationFile(event, uploadedFile);
      } else {
        this.validateVoteFile(event, uploadedFile);
      }
      event.target.labels[0].innerText = file.name;
    }).catch(error => {
      if (error.message !== 'Cannot read property \'size\' of undefined') {
        this.messageService.displayMessage({ warninglevel: 1, message: error.message }, () => { });
      }
    });
  }

  public saveVotes(): void {
    const model = {
      token: this.route.snapshot.params.token,
      meeting: this.votationOptions.meeting,
      participant: this.votationOptions.participant.id,
      voteFile: this.votesFile.azurePath,
      procurationFile: this.procurationFile.azurePath
    };

    this.meetingParticipantService.saveDistributorVotes(model).subscribe(response => {
      if (response.warningLevel === WarningLevel.Success) {
        this.cancelModal.nativeElement.click();
        this.messageService.displayMessage({ warninglevel: 0, message: 'Votos cadastrados com sucesso.' }, () => {
          this.router.navigateByUrl(
            `/conta-ordem/` +
            encodeURIComponent(this.route.snapshot.params.token) + '/' +
            encodeURIComponent(this.route.snapshot.params.meeting) + '/' +
            encodeURIComponent(localStorage.getItem('documentNumberLogin') ?? '')
          );
        });
      } else {
        this.messageService.displayMessage({ warninglevel: 1, message: 'Ocorreu um erro ao cadastrar votos.' }, () => {
          if (!!response.content.voteFileValidations) {
            const inconsistencyFile = this.inconsistencyFiles.find(f => f.type === typeFile.CSV);
            inconsistencyFile.url = response.content.voteFileValidations;
            inconsistencyFile.success = false;
          }

          if (!!response.content.procurationFileValidations) {
            const inconsistencyFile = this.inconsistencyFiles.find(f => f.type !== typeFile.CSV);
            inconsistencyFile.url = response.content.procurationFileValidations;
            inconsistencyFile.success = false;
          }

          this.validationsCard.nativeElement.click();
        });
      }
    });
  }

  public openFile(urlFile) {
    this.fileService.getUrlSafe(this.route.snapshot.params.token, urlFile)
      .then(urlSafe => {
        window.openResourceLocalUrl(urlSafe, window.getMimeType(urlFile.toLowerCase()));
      });
  }

  private validateProcurationFile(event: Event, uploadedFile: IFileUploaded): void {
    this.inconsistencyFiles = this.inconsistencyFiles.filter(f => f.type !== typeFile.Zip || f.type !== typeFile.PDF);
    if (uploadedFile.name.toLowerCase().includes('.zip')) {
      const model = {
        token: this.route.snapshot.params.token,
        meeting: this.votationOptions.meeting,
        participant: this.votationOptions.participant.id,
        azurePath: uploadedFile.azurePath,
        votesFile: this.votesFile.azurePath
      };

      this.meetingParticipantService
        .validateProcurationFile(model)
        .pipe(map((response: Envelope<string>) => {
          return {
            name: uploadedFile.name,
            url: response.content,
            type: typeFile.Zip,
            success: response.warningLevel === WarningLevel.Success
          };
        }))
        .pipe(takeUntil(this.validateProcurationFile$))
        .subscribe(response => {
          this.inconsistencyFiles.push(response);
          if (!!response.success) {
            this.procurationFile = uploadedFile;
          } else {
            this.fileWithInconsistency(event);
          }
        }, error => this.whenFileValidationFail(event, error)
        );
    } else if (uploadedFile.name.toLowerCase().includes('.pdf')) {
      this.procurationFile = uploadedFile;
      this.inconsistencyFiles.push({
        name: uploadedFile.name,
        url: '',
        type: typeFile.Zip,
        success: true
      });
    }
  }

  private validateVoteFile(event: Event, uploadedFile: IFileUploaded): void {
    if (uploadedFile.name.toLowerCase().includes('.csv')) {
      const model = {
        token: this.route.snapshot.params.token,
        meeting: this.votationOptions.meeting,
        participant: this.votationOptions.participant.id,
        azurePath: uploadedFile.azurePath
      };

      this.meetingParticipantService
        .validateVoteFile(model)
        .pipe(map((response: Envelope<string>) => {
          return {
            name: uploadedFile.name,
            url: response.content,
            type: typeFile.CSV,
            success: response.warningLevel === WarningLevel.Success
          };
        }))
        .pipe(takeUntil(this.validateVoteFile$))
        .subscribe(response => {
          this.inconsistencyFiles = this.inconsistencyFiles.filter(f => f.type !== typeFile.CSV);
          this.inconsistencyFiles.push(response);
          if (!!response.success) {
            this.votesFile = uploadedFile;
          } else {
            this.fileWithInconsistency(event);
          }
        }, error => this.whenFileValidationFail(event, error)
        );
    }
  }

  private fileWithInconsistency(event: Event): void {
    if (!event.target) { return; }
    const input = event.target as HTMLInputElement;
    input.files = null;
    this.validationsCard.nativeElement.click();
    input.labels[0].innerText = 'Escolher arquivo';
  }

  private whenFileValidationFail(event: any, error: string): void {
    event.target.labels[0].innerText = 'Escolher arquivo';
    this.messageService.displayMessage({ warninglevel: WarningLevel.Warning, message: error }, () => { });
  }

  private openModalInfo() {
    $('#modal-upload-votes-info').modal('show');
  }

  public closeModalInfo() {
    $('#modal-upload-votes-info').modal('hide');
  }

}
