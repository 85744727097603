import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable()
export class DateService {

    public formatarString(data: string, mascara: string): string {
        if (data != null && data !== undefined) {
            const info = data.split('/');
            data = `${info[2]}-${info[1]}-${info[0]}T00:00:00`;
            return moment(new Date(data)).format(mascara);
        }
        return '';
    }

    public today(): string {
        const date = new Date();
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return (year + '-' + month + '-' + day);
    }
}
