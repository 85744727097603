export class AcceptanceTermVerification{
    token: string;
    meeting: string;
    documentNumber: string;
    codCo: string;

    constructor(
        token: string,
        meeting: string,
        documentNumber: string
    ) {
        this.token = token;
        this.meeting = meeting;
        this.documentNumber = documentNumber;
    }
}