<form [formGroup]="this.form">
  <span class="fs-6">Comentário</span>
  <span *ngIf="deliberation.commentType == commentType.YES_MANDATORY" class="text-danger">*</span>
  <div class="row space">
    <div class="col">
      <textarea type="text" class="form-control shadow-inset-2" id="{{identityCommentOption}}"
        [formControlName]="formControlNameComment" placeholder="Insira aqui o comentário da deliberação {{deliberation.index}}"
         maxlength="8000" (keyup)="countChar()"></textarea>
      <div class="col-sm-12 col-md-12 d-flex align-items-center justify-content-end mb-g">
        {{deliberation.sizeCommentText}}/ 8000</div>
    </div>
  </div>
</form> 
      