import { CompanyRepresentativeService } from './../pages/company-representative/services/company-representative.service';
// import { AuthService } from './application/auth.service';
import { APIService } from './application/api.service';
import { MessageService } from './application/message.service';
import { HttpErrorHandler } from './application/httperrorhandler.service';
import { CacheService } from './application/cache.service';
import { MessageSwalService } from './application/messageswal.service';
import { VoteService } from '../pages/vote/services/vote.service';
import { AcceptanceTermService } from '../pages/vote/services/acceptanceTerm.service';
import { FileService } from './application/file.service';
import { NavigationService } from '../pages/vote/services/navigation.service';
import { DateService } from './application/date.service';
import { MeetingParticipantService } from '../pages/conta-ordem/services/meetingParticipant.service';

export const serviceProviders = [
    APIService,
    // AuthService,
    CacheService,
    // ContextService,
    HttpErrorHandler,
    MessageService,
    MessageSwalService,
    VoteService,
    MeetingParticipantService,
    AcceptanceTermService,
    CompanyRepresentativeService,
    MessageSwalService,
    NavigationService,
    FileService,
    DateService
];
