import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { VoteComponent } from './pages/vote/vote.component';
import { VoteIndexDocComponent } from './pages/vote/vote-index-doc/vote-index-doc.component';
import { CompanyRepresentativeComponent } from './pages/company-representative/company-representative.component';
import { ContaOrdemComponent } from './pages/conta-ordem/conta-ordem.component';
import { ContaOrdemAuthComponent } from './pages/vote/conta-ordem-auth/conta-ordem-auth.component';

const routes: Routes = [
  { path: '', redirectTo: 'not-found', pathMatch: 'full' },
  { path: 'conta-ordem/:token/:meeting', component: ContaOrdemComponent},
  { path: 'conta-ordem/:token/:meeting/:documentNumberLogin', component: ContaOrdemComponent},
  { path: 'conta-ordem-auth', component: ContaOrdemAuthComponent},
  { path: 'company-representative/:token/:meeting', component: CompanyRepresentativeComponent},
  { path: 'company-representative/:token/:meeting/:portal', component: CompanyRepresentativeComponent},
  { path: 'vote/:token/:meeting', component: VoteComponent},
  { path: 'vote/:token/:meeting/:portal', component: VoteComponent},
  { path: 'vote/:token/:meeting/:documentNumberLogin', component: VoteComponent},
  { path: 'vote/:token/:meeting/:documentNumberLogin/:codCo', component: VoteComponent},
  { path: 'vote', component: VoteComponent},
  { path: 'vote-index-doc', component: VoteIndexDocComponent},
  { path: 'not-found', component: NotFoundComponent},
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
