import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Deliberation } from '../../../models/votation.model';
import { NavigationService } from '../../../services/navigation.service';

declare var $: any;
@Component({
  selector: 'app-vote-item-option',
  templateUrl: './vote-item-option.component.html',
  styleUrls: ['./vote-item-option.component.scss']
})
export class VoteItemOptionComponent implements OnInit, OnChanges {

  @Input() deliberation: Deliberation;
  @Input() canVote: boolean;
  @Input() form: FormGroup;

  constructor(private navigationService: NavigationService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (!this.formCreated()) {
      this.initForm();
      const result = this.deliberation.options.find(f => f.isChecked == true);
      if (result != null) {    
        $('#btnSave').text('Alterar voto');    
        this.form.get(this.formControlNameVote).setValue(result.id);        
      }
    }
  }

  get disableVote(): boolean {
    if (this.canVote) {
      return false;
    }
    return true;
  }

  get identityVote(): string {
    return this.deliberation.deliberation;
  }

  identityVoteOption(voteOption: string): string {
    return this.deliberation.deliberation + voteOption;
  }

  get formControlNameVote(): string {
    return 'vote' + this.identityVote;
  }

  private formCreated(): boolean {
    return this.form.contains(this.formControlNameVote);
  }

  private initForm(): void {
    this.form.addControl(this.formControlNameVote, new FormControl('', Validators.required));
    this.navigationService.createFormControlNameVote(this.formControlNameVote);
  }

}
