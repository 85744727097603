import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmpty } from 'src/app/framework/core';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { CompanyRepresentativeAuthentication } from './models/Company-representative-authentication.model';
import { CompanyRepresentativeRecommendation } from './models/company-representative-recommendation.model';
import { CompanyRepresentative } from './models/company-representative.model';
import { CompanyRepresentativeService } from './services/company-representative.service';
import { dateValidator } from 'src/app/validators/date-validator';
import { DateService } from 'src/app/services/application/date.service';

declare var $: any;
@Component({
  selector: 'app-company-representative',
  templateUrl: './company-representative.component.html',
  styleUrls: ['./company-representative.component.scss']
})
export class CompanyRepresentativeComponent implements OnInit {

  form: FormGroup;

  representations: any[] = [{ id: 1, text: 'Individual' }, { id: 2, text: 'Conjunta' }];
  representatives: Array<CompanyRepresentativeRecommendation> = [];
  representationMean = 1;
  company: any;

  private _error = '';
  private _saving: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dateService: DateService,
    private route: ActivatedRoute,
    private messageService: MessageSwalService,
    private representativeService: CompanyRepresentativeService) {
    if (!isNullOrEmpty(this.route.snapshot.params.token) &&
      !isNullOrEmpty(this.route.snapshot.params.meeting)) {
      localStorage.setItem('token', this.route.snapshot.params.token);
      localStorage.setItem('meeting', this.route.snapshot.params.meeting);
    }
  }

  get isLoading(): boolean {
    return this.company == null;
  }

  get messageValidation(): boolean {
    return true;
  }

  get isSaving(): boolean {
    return this._saving;
  }

  get validation(): string {
    return this._error;
  }

  get textIndication(): string {
    const formArray = (this.form.get('representatives') as FormArray);
    const representatives = (formArray.getRawValue() as CompanyRepresentative[]);
    const representativesSelected = representatives.filter(representative => !isNullOrEmpty(representative.id));
    if (representativesSelected.length > 0) {
      return 'Alterar';
    }
    return 'Salvar';
  }

  get textRepresentation(): string {
    if (this.form.get('representation').value === '1') {
      return 'Representação Individual: Necessário informar somente 1 representante.';
    }

    return 'Representação Conjunta: Necessário informar 2 representantes obrigatoriamente.';
  }

  async ngOnInit(): Promise<void> {
    this._saving = false;
    if (this.verifyParams()) {
      this.initForm();
      await this.authentication();
    } else {
      localStorage.clear();
      this.router.navigate([`/not-found`]);
    }
  }

  changeRepresentation(representation: number): void {
    const formArray = (this.form.get('representatives') as FormArray);
    if (representation === 1 && formArray.length > 0) {
      const representatives = (formArray.getRawValue() as CompanyRepresentative[]);
      const representativesSelected = representatives.filter(representative => representative.selected);
      if (representativesSelected.length > 1) {
        this.form.get('representation').setValue('2');
        this._error = 'Não é possível conter mais de uma indicação para a forma de representação Individual.';
      }
    }
  }

  async save(): Promise<void> {
    if (this.form.invalid) {
      return;
    }
    let cpfDuplicate = false;
    this._saving = true;
    const representatives: any[] = [];
    const formArray = (this.form.get('representatives') as FormArray);
    (formArray.getRawValue()).forEach(representative => {
      const position = representatives.findIndex(r => r.documentNumber === representative.documentNumber);
      if (position > -1) {
        cpfDuplicate = true;
        return;
      }
      representatives.push(
        {
          id: representative.id,
          name: representative.name,
          documentNumber: representative.documentNumber,
          phone: representative.phone,
          email: representative.email,
          meetingUser: this.company.meetingUser,
          procurationEndDate: representative.procurationEndDate,
          procurationAzurePath: representative.procurationAzurePath,
          selected: representative.selected,
          excluded: representative.excluded
        });
    });

    if (cpfDuplicate) {
      this._saving = false;
      this.messageService.displayMessage({ warninglevel: 1, message: 'Existem representantes com o mesmo CPF.' }, () => { });
      return;
    }
    const model = {
      token: localStorage.getItem('token'),
      meeting: this.company.meeting,
      meetingUser: this.company.meetingUser,
      representatives,
    };

    await this.representativeService.save(model).toPromise()
      .then(_ => {
        this._saving = false;
        this.messageService.displayMessage({ warninglevel: 0, message: 'Indicação realizada com sucesso.' }, () => { });
        const formArray = (this.form.get('representatives') as FormArray);
        formArray.clear();
        this.authentication();
      })
      .catch(error => {
        this._saving = false;
        this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
      });

  }

  canSave(): boolean {
    if (this.form.invalid) {
      return false;
    }

    if (!this.company.currentMeeting) {
      return false;
    }

    if (!this.company.canIndicate) {
      return false;
    }

    const formArray = (this.form.get('representatives') as FormArray);
    const representatives = (formArray.getRawValue() as CompanyRepresentative[]);
    const representativesSelected = representatives.filter(representative => representative.selected);

    if (this.form.get('representation').value === '1' && representativesSelected.length < 1) {
      return false;
    } else if (this.form.get('representation').value === '2' && representativesSelected.length < 2) {
      return false;
    }
    return true;
  }

  get disableRepresentation(): boolean {
    if (this.company.currentMeeting) {
      return false;
    }
    return true;
  }

  private verifyParams(): boolean {
    return !isNullOrEmpty(localStorage.getItem('token')) &&
      !isNullOrEmpty(localStorage.getItem('meeting'));
  }

  private getParams(): CompanyRepresentativeAuthentication {
    if (!this.verifyParams()) {
      return null;
    }
    return new CompanyRepresentativeAuthentication(
      localStorage.getItem('token'),
      localStorage.getItem('meeting'));
  }

  private initForm(): void {
    this.form = this.fb.group({
      meeting: '',
      currentMeeting: '',
      meetingUser: '',
      representation: ['1', Validators.required],
    });
  }

  private createItem(representative: any): FormGroup {
    const formGroup = this.fb.group({
      id: [representative.id, []],
      name: [representative.name, Validators.required],
      documentNumber: [representative.documentNumber, Validators.required],
      phone: [representative.phone, Validators.required],
      email: [representative.email, Validators.required],
      procurationEndDate: [this.dateService.formatarString(representative.procurationEndDate, 'yyyy-MM-DD'), [Validators.required, dateValidator]],
      attachment: this.fb.group({
        id: [representative.attachment.id, []],
        name: [representative.attachment.name, []],
        azurePath: [representative.attachment.azurePath, []]
      }),
      procurationAzurePath: [representative.procurationAzurePath, Validators.required],
      selected: [representative.selected, Validators.required],
      excluded: [representative.excluded, []],
      canEditProcurationEndDate: [representative.canEditProcurationEndDate, []]
    });

    if (this.company.scope.quantityFilesAttach > 0) {
      formGroup.addControl('attachments', new FormArray([]));
    }

    return formGroup;
  }

  private async authentication(): Promise<void> {
    await this.representativeService.authenticate(this.getParams()).toPromise()
      .then(_ => {
        this.company = _.content;
        this._error = this.company.messageValidation;
        localStorage.setItem('token', this.company.token);
        this.form.get('meeting').setValue(this.company.meeting);
        this.form.get('currentMeeting').setValue(this.company.currentMeeting);
        this.form.get('meetingUser').setValue(this.company.meetingUser);
        this.form.get('representation').setValue(this.company.representation);

        this.company.representatives.forEach((representative: any) => {
          const formArray = (this.form.get('representatives') as FormArray);
          if (formArray === null) {
            this.form.addControl('representatives', this.fb.array([this.createItem(representative)]));
          } else {
            formArray.push(this.createItem(representative));
          }
        });
      })
      .catch(error => {
        this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
        this.router.navigate([`/not-found`]);
      });
  }
}
