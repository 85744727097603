<div class="row h-100 d-flex justify-content-center align-items-center">
  <div class="col-8" *ngIf="!isLoading">
    <div class="card space">
      <div class="card-header" [style.background-color]="company.scope.baseColor">
        <img [src]="company.scope.brandImageUrl" class="card-header-logo logo" alt="">
      </div>
      <h5 class="card-header">
        {{company?.name}}
      </h5>
      <div class="row space" *ngIf="messageValidation">
        <div class="col text-center">
          <span class="text-warning">{{validation}}</span>
        </div>
      </div>
      <div class="row space">
        <div class="col text-center">
          <h4>{{company?.meetingName}}</h4>
        </div>
      </div>      
      <div class="card-body card-color">
        <form [formGroup]="form">
          <div class="card space">
            <div class="card-header">
              <div class="row">
                <div class="col">
                  <span>Forma de representação</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="text-info text-start" style="font-size: 0.7rem;">{{textRepresentation}}</span>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="col-auto text-center">
                <div class="row">
                  <div class="col" *ngFor="let option of representations">
                    <div class="form-check form-check-inline icon-pointer">
                      <input class="form-check-input" type="radio" id="{{option.id}}" value="{{option.id}}"
                        [attr.disabled]="disableRepresentation ? true : null" (change)="changeRepresentation(option.id)"
                        formControlName="representation" required>
                      <label class="form-check-label icon-pointer" for="{{option.id}}">{{option.text}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-company-representative-item [form]="this.form" [currentMeeting]="this.company.currentMeeting"
            [canIndicate]="this.company.canIndicate" [quantityFilesAttach]="this.company.scope.quantityFilesAttach">
          </app-company-representative-item>
        </form>
        <div class="row space" *ngIf="this.form.valid">
          <div class="col">
            <span class="text-info text-start">Necessário indicar representante(s) para salvar, favor selecionar o ícone de estrela
              para esta ação.</span>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button type="button" class="btn btn-success btn-block" (click)="save()"
              [disabled]="!canSave() || isSaving">{{textIndication}} <span class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true" [hidden]="!isSaving"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row-12 d-flex justify-content-center" *ngIf="isLoading">
    <div class="spinner-border" role="status">
    </div>
  </div>
</div>