// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { version } from '../../package.json';
export const environment = {
  production: false,
  //baseApiUrl: 'http://localhost:7071/api',
  baseApiUrl: 'https://validmeetinghomolog.azurewebsites.net/api',
  testEnvironment: "votehomolog.valid.fund",
  vendorEmailAddress: "naoresponda@valid.fund",
  isHomolog: true,
  "version": version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
