import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavigationService } from '../../../services/navigation.service';
import { CommentType, Deliberation } from '../../../models/votation.model';

declare var $: any;
@Component({
  selector: 'app-vote-item-comment',
  templateUrl: './vote-item-comment.component.html',
  styleUrls: ['./vote-item-comment.component.scss']
})
export class VoteItemCommentComponent implements OnInit {

  @Input() deliberation: Deliberation;
  @Input() showComment: boolean;
  @Input() form: FormGroup;
  public commentType: typeof CommentType = CommentType;

  constructor(private navigationService: NavigationService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (!this.formCreated()) {
      this.initForm();
      const result = this.deliberation.commentText;
      if (result != null) {
        $('#btnSave').text('Alterar voto');
        this.form.get(this.formControlNameComment).setValue(result);
      }
    }
  }

  get identityVote(): string {
    return this.deliberation.deliberation;
  }

  identityCommentOption(voteOption: string): string {
    return this.deliberation.deliberation + voteOption;
  }

  get formControlNameComment(): string {
    return 'comment' + this.identityVote;
  }

  private formCreated(): boolean {
    return this.form.contains(this.formControlNameComment);
  }

  private initForm(): void {
    if(this.deliberation.commentType == CommentType.YES_MANDATORY){
      this.form.addControl(this.formControlNameComment, new FormControl('', Validators.required));
    }
    else{
      this.form.addControl(this.formControlNameComment, new FormControl(''));
    }
    this.navigationService.createFormControlNameComment(this.formControlNameComment);
  }

  public countChar() {
    let maxSizeTextArea = 8000;
    let index = 0;
    var texto = this.form.get(this.formControlNameComment).value;

    let len = texto.length;
    if (len > maxSizeTextArea) {
      this.deliberation.sizeCommentText = this.deliberation[index].sizeCommentText.substring(0, maxSizeTextArea);
    } else {
      this.deliberation.sizeCommentText = (maxSizeTextArea - len);
    }

  }

}
