export class AcceptanceTermAnswer{
    token: string;
    meeting: string;
    isAccepted : boolean;
    documentNumber: string;

    constructor(
        token: string,
        meeting: string,
        isAccepted: boolean,
        documentNumber: string
    ) {
        this.token = token;
        this.meeting = meeting;
        this.isAccepted = isAccepted;
        this.documentNumber = documentNumber;
    }
}